/* eslint-disable no-console */
import { Web3Provider } from "@ethersproject/providers";
import {
  useAccount,
  useConnect,
  useDisconnect,
  useSwitchChain,
  Connector,
} from "wagmi";
import { useCallback, useMemo } from "react";
import { ChainEnum, ChainIdEnum } from "../../enums/chain";
import { LocalStorageKeyEnum } from "../../enums/localStorage";
import { CHAIN_EXPLORER_URLS, L2_CHAIN_EXPLORER_URLS } from "../../utils/chain";
import { isDevelopment } from "../../utils/env";
import {
  supportedChainId,
  supportedSigningChainIds,
} from "../../utils/wallet/connectors";
import { WalletEnum } from "../../utils/wallet/types";
import { useEthersWeb3Provider } from "./useEthersProvider";

export const allBrowserWallets = [
  WalletEnum.METAMASK,
  WalletEnum.BRAVE,
] as const;
export type IBrowserWalletType = (typeof allBrowserWallets)[number];

export interface IWallet {
  chainId: number | undefined;
  active: boolean;
  activate: (wallet: WalletEnum) => Promise<void>;
  deactivate: () => Promise<void>;
  account: string | null | undefined;
  connectedWallet: WalletEnum | undefined;
  provider: Web3Provider | undefined;
  hasBrowserWallet: boolean;
  connector?: Connector | undefined;
  isWrongNetwork: boolean;
  supportedChainId: ChainIdEnum;
  setChainToSwitch: (id: ChainIdEnum) => Promise<void>; // Dispatch<SetStateAction<ChainIdEnum | null>>;
  explorerURL: string;
  l2ExplorerURL: string;
}

type ChainToId = {
  [key in ChainEnum]: ChainIdEnum;
};
export const CHAINS_TO_ID: ChainToId = {
  [ChainEnum.NotSelected]: ChainIdEnum.NONE,
  [ChainEnum.Ethereum]: ChainIdEnum.ETH_MAINNET,
  [ChainEnum.SepoliaTestnet]: ChainIdEnum.SEPOLIA_TESTNET,
  [ChainEnum.AevoLocalnet]: ChainIdEnum.LOCAL_TESTNET,
  [ChainEnum.Optimism]: ChainIdEnum.OPTIMISM,
  [ChainEnum.OptimismTestnet]: ChainIdEnum.OPTIMISM_TESTNET,
  [ChainEnum.Arbitrum]: ChainIdEnum.ARBITRUM,
  [ChainEnum.ArbitrumTestnet]: ChainIdEnum.ARBITRUM_TESTNET,
  [ChainEnum.ArbitrumLocal]: ChainIdEnum.ARBITRUM_LOCAL,
  [ChainEnum.LineaMainnet]: ChainIdEnum.LINEA_MAINNET,
  [ChainEnum.Base]: ChainIdEnum.BASE,
};

type IdToChain = {
  [key in ChainIdEnum]: ChainEnum;
};
export const ID_TO_CHAINS: IdToChain = {
  [ChainIdEnum.NONE]: ChainEnum.NotSelected,
  [ChainIdEnum.ETH_MAINNET]: ChainEnum.Ethereum,
  [ChainIdEnum.SEPOLIA_TESTNET]: ChainEnum.SepoliaTestnet,
  [ChainIdEnum.LOCAL_TESTNET]: ChainEnum.AevoLocalnet,
  [ChainIdEnum.OPTIMISM]: ChainEnum.Optimism,
  [ChainIdEnum.OPTIMISM_TESTNET]: ChainEnum.OptimismTestnet,
  [ChainIdEnum.ARBITRUM]: ChainEnum.Arbitrum,
  [ChainIdEnum.ARBITRUM_TESTNET]: ChainEnum.ArbitrumTestnet,
  [ChainIdEnum.ARBITRUM_LOCAL]: ChainEnum.ArbitrumLocal,
  [ChainIdEnum.LINEA_MAINNET]: ChainEnum.ArbitrumLocal,
  [ChainIdEnum.BASE]: ChainEnum.Base,
};

const impersonateAddress = "";

export const useWallet = (): IWallet => {
  const {
    address: accountEth,
    chainId: chainIdEth,
    isConnected: isActiveEth,
    chainId: activeChainId,
    connector: connectorEth,
  } = useAccount();

  const provider = useEthersWeb3Provider({ chainId: activeChainId });

  const { connectors, connectAsync } = useConnect();
  const { connectors: disconnectConnectors, disconnectAsync } = useDisconnect();
  const { switchChain } = useSwitchChain();

  // Returns the window's connector
  const windowEthereumObj = useMemo(() => {
    const windowObj: any | undefined = window as any;
    return windowObj?.ethereum;
  }, []);

  const connectedWallet = useMemo(() => {
    if (!connectorEth || !isActiveEth) {
      return undefined;
    }

    const isBraveWallet = !!windowEthereumObj?.isBraveWallet;
    if (isBraveWallet) {
      return WalletEnum.BRAVE;
    }

    switch (connectorEth.id) {
      case "metaMaskSDK":
        return WalletEnum.METAMASK;
      case "walletConnect":
        return WalletEnum.WALLETCONNECT;
      case "coinbaseWalletSDK":
        return WalletEnum.WALLETLINK;
      default:
        return WalletEnum.METAMASK;
    }
  }, [connectorEth, isActiveEth, windowEthereumObj?.isBraveWallet]);

  const setChainToSwitch = useCallback(
    async (id?: ChainIdEnum) => {
      if (!id || id === activeChainId || !connectorEth) {
        return;
      }

      try {
        switchChain({
          connector: connectorEth,
          chainId: id,
        });
      } catch (switchError: any) {
        // Do nothing
      }
    },
    [activeChainId, connectorEth, switchChain]
  );

  const deactivateEth = useCallback(async () => {
    localStorage.removeItem(LocalStorageKeyEnum.LAST_CONNECTED_WALLET);
    try {
      disconnectConnectors.forEach((connector) => {
        disconnectAsync({ connector });
      });
    } catch (error: any) {
      throw Error(`Error deactivating: ${error.message}`);
    }
  }, [disconnectAsync, disconnectConnectors]);

  const activate = useCallback(
    async (wallet: WalletEnum) => {
      try {
        if (!isActiveEth) {
          const walletConnector = () => {
            switch (wallet) {
              case WalletEnum.METAMASK:
                return connectors.find(
                  (connector) => connector.id === "metaMaskSDK"
                );
              case WalletEnum.WALLETCONNECT:
                return connectors.find(
                  (connector) => connector.id === "walletConnect"
                );
              case WalletEnum.WALLETLINK:
                return connectors.find(
                  (connector) => connector.id === "coinbaseWalletSDK"
                );
              case WalletEnum.BRAVE:
                return connectors.find(
                  (connector) => connector.id === "injected"
                );
              default:
                return undefined;
            }
          };

          const wagmiConnector = walletConnector();
          if (wagmiConnector) {
            await connectAsync({ connector: wagmiConnector });
            localStorage.setItem(
              LocalStorageKeyEnum.LAST_CONNECTED_WALLET,
              String(wallet)
            );
          }
        }
      } catch (error: any) {
        throw Error(`Error activating: ${error.message}`);
      }
    },
    [connectAsync, connectors, isActiveEth]
  );

  return {
    chainId: chainIdEth,
    active: isActiveEth,
    activate,
    deactivate: deactivateEth,
    account:
      isDevelopment() && impersonateAddress ? impersonateAddress : accountEth,
    connectedWallet,
    hasBrowserWallet: !!windowEthereumObj,
    provider: isActiveEth ? provider : undefined,
    connector: connectorEth,
    // If true, user is connected to an unsupported chain
    // Only enforces wrong network if not in development
    isWrongNetwork:
      Boolean(chainIdEth) &&
      !supportedSigningChainIds.includes(chainIdEth as ChainIdEnum),
    supportedChainId,
    setChainToSwitch,
    explorerURL: CHAIN_EXPLORER_URLS[supportedChainId],
    l2ExplorerURL: L2_CHAIN_EXPLORER_URLS[supportedChainId],
  };
};

export default useWallet;
