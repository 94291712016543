type IEstimate = {
  [key in "deposit" | "withdrawal" | "socketDeposit"]: {
    value: number;
    unit: string;
  };
};

export const ONCHAIN_ESTIMATES: IEstimate = {
  deposit: {
    value: 1,
    unit: "minute",
  },
  socketDeposit: {
    value: 5,
    unit: "minutes",
  },
  withdrawal: {
    value: 5,
    unit: "minutes",
  },
} as const;
