import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetAccount200Response } from "../../../../codegen-api";
import { COLORS } from "../../../../constants/design/colors";
import { FONT_SIZE } from "../../../../constants/design/fontSize";
import { SPACING } from "../../../../constants/design/spacing";
import {
  AccountStateEnum,
  AuthContext,
} from "../../../../contexts/AuthContext";
import { ConnectWalletContext } from "../../../../contexts/ConnectWalletContext";
import { IPerpsMarket } from "../../../../contexts/MarketInstrumentContext/useGetMarkets";
import { useAccountLeverage } from "../../../../hooks/api/leverage/useAccountLeverage";
import { useToast } from "../../../../hooks/toast";
import { getAssetLogo } from "../../../../utils/asset/assets";
import { roundToNearest } from "../../../../utils/math";
import { ToastEnum, ToastStatusEnum } from "../../../../utils/toast";
import { BaseModal } from "../../../BaseModal";
import { Button, ButtonThemeEnum } from "../../../Buttons/styles";
import { Input } from "../../../shared/Input";
import { WarningLabel } from "../../style";
import {
  LeverageButton,
  LeverageButtonsWrapper,
  LeverageDescription,
  LeverageInputWrapper,
  LeverageLabel,
  X,
} from "../style";

interface ILeverageModalProps {
  show?: boolean;
  onHide?: () => void;
  accountData?: GetAccount200Response;
  instrument?: IPerpsMarket;
  onUpdateLeverageSuccess: () => void;
}

const LEVERAGE_STEPS = [1, 2, 5, 10, 20];
const ALT_LEVERAGE_STEPS = [1, 2, 3, 5];
export function LeverageModal({
  show,
  onHide,
  instrument,
  accountData,
  onUpdateLeverageSuccess,
}: ILeverageModalProps) {
  const { addToast, addErrorToast } = useToast();
  const { updateAccountLeverage } = useAccountLeverage();
  const { t: apiErrors } = useTranslation("apiErrors");
  const { t } = useTranslation("app", {
    keyPrefix: "TradeForm.PerpsTradeForm.LeverageModal",
  });

  const { setShowConnectModal } = useContext(ConnectWalletContext);
  const { accountApiKeyState, account } = useContext(AuthContext);

  const [showReduceWarning, setShowReduceWarning] = useState(false);

  const accountLeverage = useMemo(
    () =>
      accountData?.leverages?.find(
        (lev) => lev.instrument_id === instrument?.instrument_id
      )?.leverage,
    [accountData?.leverages, instrument?.instrument_id]
  );

  // Account max leverage takes precedence before asset max leverage
  const maxLeverage = useMemo(() => {
    const lev = accountLeverage
      ? Number(accountLeverage)
      : Number(instrument?.max_leverage);
    return lev || 0;
  }, [accountLeverage, instrument?.max_leverage]);

  const maxLeverageSteps = useMemo(() => {
    const maxAssetLeverage = Number(instrument?.max_leverage);

    if (Number(maxAssetLeverage) <= 5) {
      return ALT_LEVERAGE_STEPS.filter(
        (step) => step <= Number(maxAssetLeverage)
      );
    }

    // Filter, then only select the last 4
    return LEVERAGE_STEPS.filter(
      (step) => step <= Number(maxAssetLeverage)
    ).slice(-4);
  }, [instrument?.max_leverage]);

  const [selectedLeverage, setLeverage] = useState<number>(maxLeverage);
  useEffect(() => setLeverage(maxLeverage), [maxLeverage]);

  const onUpdateLeverage = useCallback(async () => {
    if (!instrument?.instrument_id) return;
    setShowReduceWarning(false);
    try {
      const response = await updateAccountLeverage(
        Number(instrument?.instrument_id),
        selectedLeverage
      );

      if (response.success) {
        addToast({
          type: ToastEnum.SIMPLE,
          header: t("updated_leverage_success_desc"),
          status: ToastStatusEnum.SUCCESS,
        });

        onHide?.();
        onUpdateLeverageSuccess();
      }
    } catch (error: any) {
      addErrorToast(
        t("updated_leverage_failed_desc"),
        apiErrors(error.message) || "update_leverage_failed"
      );
    }
  }, [
    addErrorToast,
    addToast,
    apiErrors,
    instrument?.instrument_id,
    onHide,
    selectedLeverage,
    t,
    updateAccountLeverage,
    onUpdateLeverageSuccess,
  ]);

  const onSetLeverage = useCallback(
    (leverage: number) => {
      const existingPosition = accountData?.positions?.find(
        (pos) => pos.instrument_id === instrument?.instrument_id
      );

      if (
        existingPosition &&
        accountLeverage &&
        leverage < Number(accountLeverage)
      ) {
        setShowReduceWarning(true);
      } else {
        setShowReduceWarning(false);
        setLeverage(leverage);
      }
    },
    [accountData?.positions, accountLeverage, instrument?.instrument_id]
  );

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = Number(e.target.value);
      onSetLeverage(roundToNearest(value, 1));
    },
    [onSetLeverage]
  );

  return (
    <BaseModal
      style={{
        width: 310,
      }}
      show={show}
      onHide={() => {
        onHide?.();
        setShowReduceWarning(false);
      }}
      title={
        <div>
          <img
            src={getAssetLogo(instrument?.underlying_asset)}
            alt={instrument?.underlying_asset}
            style={{ marginRight: SPACING.two }}
          />
          {t("asset_leverage", { asset: instrument?.underlying_asset })}
        </div>
      }
    >
      <LeverageDescription>
        {t("default_leverage_desc", { asset: instrument?.underlying_asset })}
      </LeverageDescription>
      <LeverageLabel>
        {t("asset_leverage", { asset: instrument?.underlying_asset })}
      </LeverageLabel>
      <div style={{ marginBottom: SPACING.three }}>
        <LeverageInputWrapper>
          <Input
            placeholder="0.00"
            type="number"
            step="1"
            min={1}
            max={Number(instrument?.max_leverage) || 1}
            value={selectedLeverage}
            onChange={onInputChange}
            inputStyles={{
              fontSize: FONT_SIZE.two,
              paddingLeft: 0,
              textAlign: "right",
            }}
            wrapperStyles={{
              height: "100%",
              width: 67,
            }}
            rightAccessory={<X style={{ paddingRight: 0 }}>x</X>}
          />
          <LeverageButtonsWrapper selectedColor={COLORS.blue.one}>
            {maxLeverageSteps.map((l) => (
              <LeverageButton
                key={l}
                $selected={l === selectedLeverage}
                onClick={() => onSetLeverage(l)}
              >
                {l}x
              </LeverageButton>
            ))}
          </LeverageButtonsWrapper>
        </LeverageInputWrapper>
        {showReduceWarning ? (
          <WarningLabel style={{ marginTop: SPACING.two }}>
            {t("cannot_reduce", { asset: instrument?.underlying_asset })}
          </WarningLabel>
        ) : null}
      </div>
      {accountApiKeyState === AccountStateEnum.OK ? (
        <Button
          buttonTheme={ButtonThemeEnum.NEUTRAL2}
          fullWidth
          onClick={onUpdateLeverage}
        >
          {t("update_leverage")}
        </Button>
      ) : (
        <Button
          buttonTheme={ButtonThemeEnum.HIGHLIGHT}
          onClick={() => setShowConnectModal(true)}
        >
          {!account ? t("connect_wallet") : t("complete_sign_in")}
        </Button>
      )}
    </BaseModal>
  );
}
