/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import currency from "currency.js";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSortBy, useTable } from "react-table";
import {
  GetAccount200ResponsePositionsInner,
  InstrumentTypeResponse,
  MarginTypeResponse,
  SideResponse,
} from "../../codegen-api";
import { COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { SPACING } from "../../constants/design/spacing";
import { MarketContext } from "../../contexts/MarketContext";
import { MarketInstrumentContext } from "../../contexts/MarketInstrumentContext";
import { useGetAccount } from "../../hooks/api/account/useGetAccount";
import { useGetAccumulatedFundings } from "../../hooks/api/account/useGetAccumulatedFundings";
import { useRFQ } from "../../hooks/api/rfq/useRFQ";
import { IWSSMark } from "../../hooks/wss/model/markPrice";
import { InstrumentToMark } from "../../hooks/wss/useMarkPriceWSS";
import { ITableColumn } from "../../interfaces/Table/TableColumn";
import { splitArrayByFilter } from "../../utils/array";
import { AssetResponse } from "../../utils/asset";
import { formatSizePrecision } from "../../utils/format";
import { getOptionsTypeFromSymbol } from "../../utils/instruments";
import { calculatePnl, calculatePnlPercent } from "../../utils/math";
import { getProfitTextColor, getProfitTheme } from "../../utils/strings";
import AdjustIsolatedMarginModal from "../ConfirmationModal/AdjustIsolatedMarginModal";
import PartialTPSLForPositionModal from "../ConfirmationModal/PartialTPSLModalContent/PartialTPSLForPositionModal";
import { ModalTypeEnum } from "../ConfirmationModal/TPSLModalContent/TPSLContent.types";
import TPSLForPositionModal from "../ConfirmationModal/TPSLModalContent/TPSLForPositionModal";
import { PnLCardModal } from "../PnLCardModal";
import MarketCell from "../PortfolioSettings/Table/MarketCell";
import RowActionButton from "../shared/RowActionButton";
import { Spinner } from "../shared/Spinner";
import { SpinnerContainerWrapper } from "../shared/Spinner/style";
import { DefaultCellForColumn } from "../shared/Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../shared/Table/DefaultHeaderForColumn";
import {
  Align,
  SizeFillCell,
  TPSLCell,
  TableHeaderCell,
} from "../shared/Table/style";
import PositionDetailsModal from "../shared/ViewDetailsModal/PositionDetailsModal";
import ActionButtonsCell from "./ActionButtonsCell";
import OptionPositionTableRow from "./OptionPositionTableRow";
import { IModifiedPosition } from "./model";
import {
  PositionTableRow,
  PositionsTableWrapper,
  SummaryTableRow,
} from "./style";

type IModifiedNestedOptionPositions = {
  asset: AssetResponse;
  expiryPositions: {
    [expiry: string]: {
      expiry: string;
      positions: IModifiedPosition[];
    };
  };
};

type IOptionsPositionByAsset = {
  [asset: AssetResponse]: IModifiedNestedOptionPositions;
};

interface IPositionsProps {
  accountEquity?: string;
  positions: GetAccount200ResponsePositionsInner[];
  loading?: boolean;
  selectedMarketType?: InstrumentTypeResponse;
  inLiquidation: boolean;
  isStrategyView?: boolean;
  instrumentMark: InstrumentToMark;
  emptyContent?: JSX.Element;
}

const ACTION_BUTTON_ID = "closePositionRektMeter";

function Positions({
  positions,
  loading,
  selectedMarketType,
  inLiquidation,
  isStrategyView,
  instrumentMark,
  emptyContent,
}: IPositionsProps) {
  const { t } = useTranslation("app", { keyPrefix: "Positions" });
  const { t: tooltip } = useTranslation("tooltips");
  const { setMarket } = useContext(MarketContext);
  const { setOptionType, setExpiry, setStrike, getMarketPrecision } =
    useContext(MarketInstrumentContext);
  const { data: accumulatedFundingData } = useGetAccumulatedFundings();
  const { deleteRFQsByInstrumentId } = useRFQ(undefined, false);
  const { data: accountData } = useGetAccount();

  // NOTE: IDs here are referring the position's instrument_id
  const [shareModalPositionId, setShareModalPositionId] = useState<string>();
  const [tpslModalPositionId, setTpslModalPositionId] = useState<string>();
  const [closingPositionId, setClosingPositionId] = useState<string>();
  const [viewDetailsForPositionId, setViewDetailsForPositionId] =
    useState<string>();
  const [adjustingMarginPositionId, setAdjustingMarginPositionId] =
    useState<string>();

  const [showClosePositionModal, setShowClosePositionModal] =
    useState<boolean>(false);

  const [partialTPSLModalId, setPartialTPSLModalId] = useState<string>();
  const [detailPartialTPSLModalId, setDetailPartialTPSLModalId] =
    useState<string>();

  const modifiedPositions: IModifiedPosition[] = useMemo(
    () =>
      positions.map((p) => {
        const mark: IWSSMark | undefined = instrumentMark[p.instrument_name];

        // Calculate pnl based on mark price from ticker instead
        const markPrice = Number(mark?.mark_price || p.mark_price);
        const positionValue = markPrice * Number(p.amount);
        const entryValue = Number(p.avg_entry_price || 0) * Number(p.amount);
        const unrealized_pnl = calculatePnl(entryValue, positionValue, p.side);
        const roi = calculatePnlPercent(
          unrealized_pnl,
          entryValue,
          Number(
            p.margin_type === MarginTypeResponse.Isolated
              ? p.isolated_margin
              : p.maintenance_margin
          )
        );

        const { amount_precision, price_precision } = getMarketPrecision(
          p.asset,
          p.instrument_type
        );

        const accumulatedFundingRow =
          accumulatedFundingData && accumulatedFundingData.accumulated_fundings
            ? accumulatedFundingData.accumulated_fundings.find(
                (aRow) => aRow.instrument_id === p.instrument_id
              )
            : undefined;
        const accumulatedFunding = accumulatedFundingRow
          ? Number(accumulatedFundingRow.accumulated_funding || "0")
          : undefined;
        return {
          ...p,
          mark_price: String(markPrice),
          unrealized_pnl: String(unrealized_pnl),
          roi,
          positionValue,
          amountPrecision: amount_precision,
          pricePrecision: price_precision,
          accumulatedFunding,
        };
      }),
    [accumulatedFundingData, getMarketPrecision, instrumentMark, positions]
  );

  const positionByInstrumentId = useCallback(
    (instrumentId?: string) => {
      if (!instrumentId) {
        return undefined;
      }

      return modifiedPositions.find(
        (position) => position.instrument_id === instrumentId
      );
    },
    [modifiedPositions]
  );

  const onClosePositionWithId = useCallback((instrument_id: string) => {
    setClosingPositionId(instrument_id);
    setShowClosePositionModal(true);
  }, []);

  const onClosePosition = useCallback(
    (pos: GetAccount200ResponsePositionsInner) => {
      onClosePositionWithId(pos.instrument_id);
    },
    [onClosePositionWithId]
  );

  const onViewPositionDetails = useCallback(
    (pos: GetAccount200ResponsePositionsInner) => {
      setViewDetailsForPositionId(pos.instrument_id);
    },
    []
  );

  const onSharePosition = useCallback(
    (pos: GetAccount200ResponsePositionsInner) => {
      setViewDetailsForPositionId(pos.instrument_id);
    },
    []
  );

  const showGreeks = useMemo(() => {
    // Don't show if the positions include perps that are not BTC or ETH
    if (
      modifiedPositions.some(
        (pos) =>
          pos.instrument_type === InstrumentTypeResponse.Perpetual &&
          pos.asset !== "BTC" &&
          pos.asset !== "ETH"
      )
    ) {
      return false;
    }

    // Show greeks if there are options positions, or if the perp positions are BTC or ETH
    return modifiedPositions.some(
      (pos) =>
        pos.instrument_type === InstrumentTypeResponse.Option ||
        (pos.instrument_type === InstrumentTypeResponse.Perpetual &&
          (pos.asset === "BTC" || pos.asset === "ETH"))
    );
  }, [modifiedPositions]);

  // Split positions into OPTIONS and PERPS
  const [optionPositions, perpsPositions] = splitArrayByFilter(
    modifiedPositions,
    (pos) => !!pos.option && (pos.asset === "BTC" || pos.asset === "ETH")
  );

  // Convert option positions into grouped positions
  const optionsPositionByAsset: IOptionsPositionByAsset =
    optionPositions.reduce((acc, position) => {
      if (!position.option?.expiry) {
        return acc;
      }

      if (!acc[position.asset]) {
        // Initialize empty structure
        acc[position.asset] = {
          asset: position.asset,
          expiryPositions: {},
        };
      }

      if (!acc[position.asset].expiryPositions[position.option.expiry]) {
        acc[position.asset] = {
          asset: position.asset,
          expiryPositions: {
            ...acc[position.asset].expiryPositions,
            [position.option.expiry]: {
              expiry: position.option.expiry,
              positions: [],
            },
          },
        };
      }

      // Push
      acc[position.asset].expiryPositions[
        position.option.expiry
      ].positions.push(position);
      return acc;
    }, {} as IOptionsPositionByAsset);

  // Now create all table positions
  const memoizedTablePositions: IModifiedPosition[] = useMemo(
    () => [
      ...perpsPositions,
      ...Object.values(optionsPositionByAsset).map(
        (nestedOptionsPos: IModifiedNestedOptionPositions) => {
          const normalModifiedPos: IModifiedPosition = {
            asset: nestedOptionsPos.asset,
            instrument_type: InstrumentTypeResponse.Option,
            nestedOptionsData: nestedOptionsPos.expiryPositions,

            // All these should be ignored by table
            roi: 0,
            amountPrecision: 0,
            pricePrecision: 0,
            positionValue: 0,
            instrument_id: "0",
            instrument_name: "0",
            option: {
              delta: "0",
              gamma: "0",
              vega: "0",
              theta: "0",
              iv: "0",
              rho: "0",
              option_type: undefined as any,
              strike: "0",
              expiry: "0",
            },
            iv: "0",
            amount: "0",
            side: SideResponse.Buy,
            mark_price: "0",
            avg_entry_price: "0",
            unrealized_pnl: "0",
            maintenance_margin: "0",
          };
          return normalModifiedPos;
        }
      ),
    ],
    [optionsPositionByAsset, perpsPositions]
  );

  const columns: ITableColumn<IModifiedPosition>[] = useMemo(() => {
    const generalCols: ITableColumn<IModifiedPosition>[] = [
      {
        title: t("market"),
        accessor: "instrument_name",
        tooltip: tooltip("market"),
        Cell: ({ row }) => {
          const { option, instrument_name, instrument_id } = row.original;
          const { expiry, strike } = option || {};
          const optionType = getOptionsTypeFromSymbol(instrument_name);
          const leverage = accountData?.leverages?.find(
            (lev) => lev.instrument_id === instrument_id
          )?.leverage;

          if (optionType) {
            return (
              <MarketCell
                showSettlementInProgress
                instrumentName={instrument_name}
                optionType={optionType}
                expiry={expiry}
                strike={strike}
              />
            );
          }

          return (
            <MarketCell
              showSettlementInProgress
              leverage={Number(leverage)}
              instrumentName={instrument_name}
            />
          );
        },
      },
      {
        title: t("side"),
        accessor: "side",
        align: "left",
        tooltip: tooltip("side"),
        Cell: ({ value }) => (
          <Align
            align="left"
            style={{
              color:
                value === SideResponse.Buy
                  ? COLORS.positive.one
                  : COLORS.negative.one,
            }}
          >
            {value === SideResponse.Buy ? t("long") : t("short")}
          </Align>
        ),
      },
      {
        title: t("contracts"),
        accessor: "amount",
        align: "right",
        tooltip: tooltip("positionSize"),
        Cell: ({ value, row }) => {
          if (row.original.nestedOptionsData) {
            return null;
          }
          return (
            <Align align="right">
              {formatSizePrecision(value, row.original.amountPrecision)}
            </Align>
          );
        },
      },
    ];

    const ivCols: ITableColumn<IModifiedPosition>[] = [
      {
        title: t("iv"),
        accessor: "iv",
        align: "right",
        tooltip: tooltip("iv"),
        valueExtractor: (value) =>
          value ? `${(Number(value) * 100).toFixed(2)}%` : "-",
      },
    ];
    const greekCols: ITableColumn<IModifiedPosition>[] = [
      {
        title: t("delta"),
        align: "right",
        // @ts-ignore
        accessor: "option.delta",
        id: "delta",
        tooltip: tooltip("delta"),
        Cell: ({ row }: any) => (
          <Align align="right">
            {row.original.instrument_type === InstrumentTypeResponse.Option
              ? Number(row.original.option?.delta || 0).toFixed(2)
              : Number(row.original.amount).toFixed(2)}
          </Align>
        ),
      },
      {
        title: t("gamma"),
        align: "right",
        // @ts-ignore
        accessor: "option.gamma",
        tooltip: tooltip("gamma"),
        valueExtractor: (gamma) => Number(gamma || 0).toFixed(2),
      },
      {
        title: t("vega"),
        align: "right",
        // @ts-ignore
        accessor: "option.vega",
        tooltip: tooltip("vega"),
        valueExtractor: (vega) => Number(vega || 0).toFixed(2),
      },
      {
        title: t("theta"),
        align: "right",
        // @ts-ignore
        accessor: "option.theta",
        tooltip: tooltip("theta"),
        valueExtractor: (theta) => currency(theta).format(),
      },
    ];

    const markCol: ITableColumn<IModifiedPosition>[] = [
      {
        title: t("mark_price"),
        accessor: "mark_price",
        align: "right",
        tooltip: tooltip("markPrice"),
        Cell: ({ value, row }) => {
          if (row.original.nestedOptionsData) {
            return null;
          }
          return (
            <Align align="right">
              {currency(value, {
                precision: row.original.pricePrecision,
              }).format()}
            </Align>
          );
        },
      },
    ];

    const perpActionsCol: ITableColumn<IModifiedPosition>[] = [
      {
        title: t("tpsl_for_position"),
        accessor: "closePositionTriggers",
        align: "right",
        tooltip: tooltip("tpslForPosition"),
        Cell: ({ value, row }) => {
          if (
            row.original.nestedOptionsData ||
            row.original.instrument_type !== InstrumentTypeResponse.Perpetual
          ) {
            return null;
          }

          return (
            <Align align="right">
              <TPSLCell>
                <span>
                  {value?.take_profit?.trigger
                    ? currency(value.take_profit?.trigger, {
                        precision: row.original.pricePrecision,
                      }).format()
                    : "-"}
                </span>
                <span>
                  {value?.stop_loss?.trigger
                    ? currency(value.stop_loss?.trigger, {
                        precision: row.original.pricePrecision,
                      }).format()
                    : "-"}
                </span>
              </TPSLCell>
              <RowActionButton
                variant={"edit"}
                onClick={(e) => {
                  e.stopPropagation();
                  setTpslModalPositionId(
                    (row.original as GetAccount200ResponsePositionsInner)
                      .instrument_id
                  );
                }}
                style={{ marginLeft: SPACING.two }}
              />
            </Align>
          );
        },
      },
      {
        title: t("partial_tpsl_for_position"),
        accessor: "partialPositionTriggers",
        align: "right",
        tooltip: tooltip("tpslForPosition"),
        Cell: ({ row }) => {
          if (
            row.original.nestedOptionsData ||
            row.original.instrument_type !== InstrumentTypeResponse.Perpetual
          ) {
            return null;
          }

          const takeProfitOrders =
            row.original.partialPositionTriggers?.take_profit ?? [];
          const stopLossOrders =
            row.original.partialPositionTriggers?.stop_loss ?? [];
          const partialPositionLength =
            takeProfitOrders.length || stopLossOrders.length || 0;
          const isPartialPositionExist = partialPositionLength > 0;

          return (
            <Align align="right">
              <RowActionButton
                variant={"partial"}
                text="Details"
                disabled={!isPartialPositionExist}
                onClick={(e) => {
                  e.stopPropagation();
                  setDetailPartialTPSLModalId(
                    (row.original as GetAccount200ResponsePositionsInner)
                      .instrument_id
                  );
                }}
                style={{ marginLeft: SPACING.two }}
              />
              <RowActionButton
                variant={"plus"}
                onClick={(e) => {
                  e.stopPropagation();
                  setPartialTPSLModalId(
                    (row.original as GetAccount200ResponsePositionsInner)
                      .instrument_id
                  );
                }}
                style={{ marginLeft: SPACING.two }}
              />
            </Align>
          );
        },
      },
    ];

    const perpsCol: ITableColumn<IModifiedPosition>[] = [
      {
        title: t("liquidation_price"),
        accessor: "liquidation_price",
        align: "right",
        tooltip: tooltip("liquidationPrice"),
        Cell: ({ value, row }) => {
          if (row.original.nestedOptionsData) {
            return null;
          }
          return (
            <Align align="right">
              {value
                ? currency(value, {
                    precision: row.original.pricePrecision,
                  }).format()
                : "-"}
            </Align>
          );
        },
      },
    ];

    const actionCols: ITableColumn<IModifiedPosition>[] = [
      {
        id: ACTION_BUTTON_ID,
        align: "right",
        Header: () => <div />,
        Cell: ({ row }: any) => (
          <ActionButtonsCell
            shareColor={
              Number(row.original.roi) >= 0
                ? COLORS.positive.one
                : COLORS.negative.one
            }
            inLiquidation={inLiquidation}
            position={row.original}
            onClosePositionWithId={onClosePositionWithId}
            setViewDetailsForPositionId={setViewDetailsForPositionId}
            setShareModalPositionId={setShareModalPositionId}
            setAdjustingMarginPositionId={setAdjustingMarginPositionId}
          />
        ),
      },
    ];

    const cols: ITableColumn<IModifiedPosition>[] = [
      ...generalCols,
      {
        title: t("avg_entry_price"),
        accessor: "avg_entry_price",
        align: "right",
        tooltip: tooltip("avgEntryPrice"),
        Cell: ({ value, row }) => {
          if (row.original.nestedOptionsData) {
            return null;
          }
          return (
            <Align align="right">
              {currency(value, {
                precision: row.original.pricePrecision,
              }).format()}
            </Align>
          );
        },
      },
      {
        title: t("position_value"),
        accessor: "positionValue",
        align: "right",
        tooltip: tooltip("positionValue"),
        Cell: ({ value, row }) => {
          if (row.original.nestedOptionsData) {
            return null;
          }
          return <Align align="right">{currency(value).format()}</Align>;
        },
      },
      ...markCol,
      {
        title: t("pnl"),
        accessor: "unrealized_pnl",
        align: "right",
        tooltip: tooltip("unrealizedPnl"),
        sortType: "basic",
        Cell: ({ value, row }) => {
          if (row.original.nestedOptionsData) {
            return null;
          }

          const { roi } = row.original;
          const color =
            Number(value) >= 0 ? COLORS.positive.one : COLORS.negative.one;
          const roiColor =
            Number(roi) >= 0 ? COLORS.positive.two : COLORS.negative.two;
          return (
            <Align align="right">
              <SizeFillCell>
                <span style={{ color }}>
                  {Number(value) >= 0 ? "+" : ""}
                  {value ? currency(value).format() : "-"}
                </span>
                <span style={{ color: roiColor }}>
                  {roi >= 0 ? "+" : ""}
                  {(roi * 100).toFixed(2)}%
                </span>
              </SizeFillCell>
            </Align>
          );
        },
      },
      ...(modifiedPositions.some(
        (pos) => pos.instrument_type === InstrumentTypeResponse.Perpetual
      )
        ? [...(isStrategyView ? [] : perpActionsCol), ...perpsCol]
        : []),
      ...(selectedMarketType === InstrumentTypeResponse.Option ? ivCols : []),
      {
        title: t("maintenance_margin"),
        align: "right",
        accessor: "maintenance_margin",
        tooltip: tooltip("maintenanceMargin"),
        Cell: ({ value, row }) => {
          const isolatedMargin = Number(row.original.isolated_margin);
          if (isolatedMargin > 0) {
            return (
              <Align align="right">
                <div style={{ flexDirection: "column" }}>
                  <div>{currency(isolatedMargin).format()}</div>
                  <div style={{ color: COLORS.blue.one }}>{t("isolated")}</div>
                </div>
              </Align>
            );
          }

          return <Align align="right">{currency(value).format()}</Align>;
        },
      },
      {
        title: t("funding"),
        align: "right",
        accessor: "accumulatedFunding",
        tooltip: tooltip("funding"),
        Cell: ({ value }) => {
          const color =
            Number(value || "0") >= 0
              ? COLORS.positive.one
              : COLORS.negative.one;
          return (
            <Align align="right">
              {value ? (
                <div style={{ color }}>{currency(value).format()}</div>
              ) : (
                <div>-</div>
              )}
            </Align>
          );
        },
      },
      ...(showGreeks ? greekCols : []),
      ...actionCols,
      // Force columns to rerender when sortInfo changes
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ];

    if (isStrategyView) {
      return [...generalCols, ...markCol];
    }

    return cols;
  }, [
    t,
    tooltip,
    modifiedPositions,
    isStrategyView,
    selectedMarketType,
    positions,
    accountData?.leverages,
    inLiquidation,
    onClosePositionWithId,
  ]);

  const summaryRow = useMemo(() => {
    const totals = modifiedPositions.reduce(
      (prev, pos) => ({
        size: prev.size + Number(pos.amount),
        pnl: prev.pnl + Number(pos.unrealized_pnl),
        margin:
          prev.margin +
          Number(
            pos.margin_type === MarginTypeResponse.Isolated
              ? pos.isolated_margin
              : pos.maintenance_margin
          ),
        delta: prev.delta + Number(pos.option?.delta) || 0,
        theta: prev.theta + Number(pos.option?.theta) || 0,
        gamma: prev.gamma + Number(pos.option?.gamma) || 0,
        vega: prev.vega + Number(pos.option?.vega) || 0,
      }),
      {
        size: 0,
        pnl: 0,
        margin: 0,
        delta: 0,
        theta: 0,
        gamma: 0,
        vega: 0,
      }
    );

    return (
      <SummaryTableRow theme={getProfitTheme(Number(totals.pnl))}>
        {columns.map((col) => {
          if (col.accessor === "instrument_name") {
            return (
              <td key="instrument_name">
                <Align align="left">{t("summary")}</Align>
              </td>
            );
          }
          if (col.accessor === "unrealized_pnl") {
            return (
              <td key="unrealized_pnl">
                <Align
                  align={col.align}
                  style={{
                    color: getProfitTextColor(Number(totals.pnl)),
                  }}
                >
                  {Number(totals.pnl) >= 0 ? "+" : ""}
                  {currency(totals.pnl).format()}
                </Align>
              </td>
            );
          }
          if (col.accessor === "maintenance_margin") {
            return (
              <td key="maintenance_margin">
                <Align align={col.align} style={{ color: TEXT_COLORS.one }}>
                  {currency(totals.margin).format()}
                </Align>
              </td>
            );
          }
          // @ts-ignore
          if (col.accessor === "option.delta") {
            return (
              <td key="option.delta">
                {/* @ts-ignore */}
                <Align align={col.align} style={{ color: TEXT_COLORS.one }}>
                  {totals.delta.toFixed(2)}
                </Align>
              </td>
            );
          }
          // @ts-ignore
          if (col.accessor === "option.gamma") {
            return (
              <td key="option.gamma">
                {/* @ts-ignore */}
                <Align align={col.align} style={{ color: TEXT_COLORS.one }}>
                  {totals.gamma.toFixed(2)}
                </Align>
              </td>
            );
          }
          // @ts-ignore
          if (col.accessor === "option.vega") {
            return (
              <td key="option.vega">
                {/* @ts-ignore */}
                <Align align={col.align} style={{ color: TEXT_COLORS.one }}>
                  {totals.vega.toFixed(2)}
                </Align>
              </td>
            );
          }
          // @ts-ignore
          if (col.accessor === "option.theta") {
            return (
              <td key="option.theta">
                {/* @ts-ignore */}
                <Align align={col.align} style={{ color: TEXT_COLORS.one }}>
                  {currency(totals.theta).format()}
                </Align>
              </td>
            );
          }
          return <td key={col.title || col.id} />;
        })}
      </SummaryTableRow>
    );
  }, [columns, modifiedPositions, t]);

  const onClosePositionModalHide = useCallback(() => {
    if (closingPositionId) {
      deleteRFQsByInstrumentId(closingPositionId);
    }

    setShowClosePositionModal(false);
  }, [closingPositionId, deleteRFQsByInstrumentId]);

  const onAdjustMarginModalHide = useCallback(() => {
    setAdjustingMarginPositionId(undefined);
  }, []);

  const onPositionDetailsModalHide = useCallback(() => {
    setViewDetailsForPositionId(undefined);
  }, []);

  const onSharePositionModalHide = useCallback(() => {
    setShareModalPositionId(undefined);
  }, []);

  const onTPSLForPositionModalHide = useCallback(() => {
    setTpslModalPositionId(undefined);
  }, []);

  const onPartialTPSLForPositionModalHide = useCallback(() => {
    setPartialTPSLModalId(undefined);
  }, []);

  const onDetailPartialTPSLForPositionModalHide = useCallback(() => {
    setDetailPartialTPSLModalId(undefined);
  }, []);

  const onSelectPosition = useCallback(
    (position: GetAccount200ResponsePositionsInner) => {
      setMarket({
        derivative: position.option
          ? InstrumentTypeResponse.Option
          : InstrumentTypeResponse.Perpetual,
        asset: position.asset,
      });
      if (position.option) {
        setOptionType(position.option?.option_type);
        setExpiry(Number(position.option?.expiry));
        setStrike(position.option?.strike);
      }
    },
    [setExpiry, setMarket, setOptionType, setStrike]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<IModifiedPosition>(
      {
        columns,
        data: memoizedTablePositions,
        defaultColumn: {
          Header: DefaultHeaderForColumn,
          Cell: DefaultCellForColumn,
        },
        autoResetSortBy: false,
      } as any,
      useSortBy
    );

  if (loading) {
    return (
      <SpinnerContainerWrapper>
        <Spinner />
      </SpinnerContainerWrapper>
    );
  }

  if (emptyContent && !memoizedTablePositions.length) {
    return emptyContent;
  }

  return (
    <>
      {positionByInstrumentId(adjustingMarginPositionId) && (
        <AdjustIsolatedMarginModal
          position={positionByInstrumentId(adjustingMarginPositionId)}
          onHide={onAdjustMarginModalHide}
        />
      )}
      {showClosePositionModal && (
        <PositionDetailsModal
          position={positionByInstrumentId(closingPositionId)}
          onHide={onClosePositionModalHide}
          accumulatedFundingData={accumulatedFundingData}
          isClosePosition
        />
      )}
      {positionByInstrumentId(viewDetailsForPositionId) && (
        <PositionDetailsModal
          position={positionByInstrumentId(viewDetailsForPositionId)}
          onHide={onPositionDetailsModalHide}
          accumulatedFundingData={accumulatedFundingData}
        />
      )}
      {!!shareModalPositionId && (
        <PnLCardModal
          pnlType="position"
          pnlData={positionByInstrumentId(shareModalPositionId)}
          onHideModal={onSharePositionModalHide}
          show={!!shareModalPositionId}
          onHidePnLCard={onSharePositionModalHide}
          hideBackButton
        />
      )}
      {!!tpslModalPositionId && (
        <TPSLForPositionModal
          position={positionByInstrumentId(tpslModalPositionId)}
          onHide={onTPSLForPositionModalHide}
          show={!!tpslModalPositionId}
          modalType={ModalTypeEnum.Close}
        />
      )}
      {!!partialTPSLModalId && (
        <TPSLForPositionModal
          position={positionByInstrumentId(partialTPSLModalId)}
          onHide={onPartialTPSLForPositionModalHide}
          show={!!partialTPSLModalId}
          modalType={ModalTypeEnum.Partial}
        />
      )}
      {!!detailPartialTPSLModalId && (
        <PartialTPSLForPositionModal
          onHide={onDetailPartialTPSLForPositionModalHide}
          show={!!detailPartialTPSLModalId}
          position={positionByInstrumentId(detailPartialTPSLModalId)}
        />
      )}
      <PositionsTableWrapper isStrategyView={isStrategyView}>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  // eslint-disable-next-line react/jsx-key
                  <TableHeaderCell
                    // We apply the sort properties to each column
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                  </TableHeaderCell>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {summaryRow}
            {rows.map((row) => {
              prepareRow(row);

              // Render nested rows instead
              if (row.original.nestedOptionsData) {
                return (
                  <OptionPositionTableRow
                    key={row.original.asset}
                    inLiquidation={inLiquidation}
                    row={row}
                    columns={columns}
                    onClickPosition={onSelectPosition}
                    onClosePosition={onClosePosition}
                    onViewPositionDetails={onViewPositionDetails}
                    onSharePosition={onSharePosition}
                    actionButtonId={ACTION_BUTTON_ID}
                  />
                );
              }

              return (
                // eslint-disable-next-line react/jsx-key
                <PositionTableRow
                  {...row.getRowProps()}
                  // If row is option row, apply row animation style
                  // expanded={!!row.original.nestedOptionsData && expandedOptionAssets.includes(row.original.asset)}
                  onClick={() => {
                    onSelectPosition(
                      row.original as GetAccount200ResponsePositionsInner
                    );
                  }}
                >
                  {row.cells.map((cell) => (
                    // eslint-disable-next-line react/jsx-key
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </PositionTableRow>
              );
            })}
          </tbody>
        </table>
      </PositionsTableWrapper>
    </>
  );
}

export default Positions;
