import { Suspense, lazy, useCallback, useContext, useState } from "react";
import { OptionsChain } from "../../components/OptionsChain";
import OptionsTradeForm from "../../components/TradeForm/OptionsTradeForm";
import { MarketContext } from "../../contexts/MarketContext";
import {
  BitcoinMarquee,
  HeaderSection,
  MarketSelectionWrapper,
  OptionsChainSection,
  OptionsSection,
  PerpsSection,
  TradeFormWrapper,
  TradesSection,
  TradingPageContent,
  TradingPageWrapper,
} from "./style";

import { InstrumentTypeResponse, OptionTypeResponse } from "../../codegen-api";
import MarketSelectionButton from "../../components/MarketSelectionButton";
import OptionsHistory from "../../components/OptionsHistory";
import PerpsOrderbookAndChart from "../../components/Perps/PerpsOrderbookAndChart";
import PerpsTradeForm, {
  IPerpsFormDefaultValues,
} from "../../components/TradeForm/PerpsTradeForm";
import LazyOptionsTradingHeader from "../../components/TradingHeader/Option/LazyOptionsTradingHeader";
import LazyPerpsTradingHeader from "../../components/TradingHeader/Perp/LazyPerpsTradingHeader";
import { COMPONENTS } from "../../constants/design/spacing";
import { MarketInstrumentContext } from "../../contexts/MarketInstrumentContext";
import useScreenSize from "../../hooks/screenSize/useScreenSize";
import usePersistentState from "../../hooks/usePersistentState";
import TradingPageTabs, { TradingPageTabEnum } from "./TradingPageTabs";
import VolatilityWarningBanner from "./VolatilityWarningBanner";

// Lazy components
const LazyTradingBottomBar = lazy(
  () =>
    import(
      /* webpackChunkName: "TradingBottomBar" */
      /* webpackPrefetch: true */
      "../../components/TradingBottomBar"
    )
);

function TradingPage() {
  const { market } = useContext(MarketContext);
  const {
    setOptionType,
    setExpiry,
    setStrike,
    selectedOptionInstrument,
    selectedPerpetualInstrument,
  } = useContext(MarketInstrumentContext);

  const { isMobileScreen } = useScreenSize();
  const {
    showOnlyCurrentMarket,
    setShowOnlyCurrentMarket,
    showBottomTray: bottomBarCollapsed,
    setShowBottomTray: setBottomBarCollapsed,
  } = usePersistentState();

  const [currentTab, setCurrentTab] = useState<TradingPageTabEnum>(
    TradingPageTabEnum.OPTIONS_CHAIN
  );

  const [perpDefaultValues, setPerpDefaultValues] =
    useState<IPerpsFormDefaultValues>();

  const onTrade = useCallback(
    (optionType: OptionTypeResponse, expiry: number, strike: string) => {
      setOptionType(optionType);
      setExpiry(expiry);
      setStrike(strike);
    },
    [setExpiry, setOptionType, setStrike]
  );

  const onPerpOrderbookRowClick = useCallback(
    (price: string, amount: string) => {
      setPerpDefaultValues({ price, amount });
    },
    []
  );

  const onToggleBottomBar = useCallback(() => {
    setBottomBarCollapsed((prev) => !prev);
  }, [setBottomBarCollapsed]);

  return (
    <>
      {!!selectedPerpetualInstrument?.pre_launch && (
        <VolatilityWarningBanner
          dismissKey={selectedPerpetualInstrument?.underlying_asset}
        />
      )}
      <TradingPageWrapper>
        {selectedPerpetualInstrument?.underlying_asset === "BITCOIN" ? (
          <BitcoinMarquee
            loop={1}
            direction="right"
            gradient={false}
            speed={100}
          >
            <img
              alt="Sonic"
              height="60px"
              src="https://hpos10i.com/funny-pictures/Hyper_Sonic_run_fly.gif"
            />
          </BitcoinMarquee>
        ) : null}

        <HeaderSection>
          <MarketSelectionButton />
          <MarketSelectionWrapper>
            {market.derivative === InstrumentTypeResponse.Option && (
              <LazyOptionsTradingHeader isMobileScreen={isMobileScreen} />
            )}
            {market.derivative === InstrumentTypeResponse.Perpetual && (
              <LazyPerpsTradingHeader
                perpInstrument={selectedPerpetualInstrument}
              />
            )}
          </MarketSelectionWrapper>
        </HeaderSection>
        {market.derivative === InstrumentTypeResponse.Option && (
          <OptionsSection
            bottomCollapsed={bottomBarCollapsed}
            bottomCollapsedHeight={`${COMPONENTS.tradeBottomBar.header}px`}
          >
            <OptionsChainSection>
              <TradingPageTabs
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
              />
              <TradingPageContent>
                {currentTab === TradingPageTabEnum.OPTIONS_CHAIN ? (
                  <OptionsChain
                    onTrade={onTrade}
                    activeInstrumentId={
                      selectedOptionInstrument?.instrument_name
                    }
                  />
                ) : (
                  <OptionsHistory />
                )}
              </TradingPageContent>
            </OptionsChainSection>

            <Suspense>
              <LazyTradingBottomBar
                collapsed={bottomBarCollapsed}
                onToggleCollapse={onToggleBottomBar}
                showOnlyCurrentMarket={showOnlyCurrentMarket}
                setShowOnlyCurrentMarket={setShowOnlyCurrentMarket}
              />
            </Suspense>
          </OptionsSection>
        )}
        {market.derivative === InstrumentTypeResponse.Perpetual && (
          <PerpsSection
            bottomCollapsed={bottomBarCollapsed}
            bottomCollapsedHeight={`${COMPONENTS.tradeBottomBar.header}px`}
          >
            <PerpsOrderbookAndChart
              onOrderbookRowClick={onPerpOrderbookRowClick}
              perpInstrument={selectedPerpetualInstrument}
            />
            <Suspense>
              <LazyTradingBottomBar
                collapsed={bottomBarCollapsed}
                onToggleCollapse={onToggleBottomBar}
                style={{ gridColumn: "1 / span 2" }}
                showOnlyCurrentMarket={showOnlyCurrentMarket}
                setShowOnlyCurrentMarket={setShowOnlyCurrentMarket}
              />
            </Suspense>
          </PerpsSection>
        )}
        <TradesSection>
          <TradeFormWrapper>
            {market.derivative === InstrumentTypeResponse.Option && (
              <OptionsTradeForm selectedInstrument={selectedOptionInstrument} />
            )}
            {market.derivative === InstrumentTypeResponse.Perpetual && (
              <PerpsTradeForm
                defaultValues={perpDefaultValues}
                perpInstrument={selectedPerpetualInstrument}
              />
            )}
          </TradeFormWrapper>
        </TradesSection>
      </TradingPageWrapper>
    </>
  );
}
export default TradingPage;
