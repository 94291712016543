import useSWR from "swr";
import axios from "axios";

export interface IAirtableValues {
  key?: string;
  title?: string;
  link?: string;
  actionTitle?: string;
  dismissable?: number;
  announcementType: string;
}

const fetchAnnouncement = async () => {
  const tableName =
    process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === "production"
      ? "Announcements"
      : "AnnouncementsTest";
  try {
    const response = await axios.get(
      `https://api.airtable.com/v0/${
        process.env.REACT_APP_AIRTABLE_ANNOUNCEMENT_TABLE_ID || ""
      }/${tableName}?view=Grid%20view`,
      {
        headers: {
          Authorization: `Bearer ${
            process.env.REACT_APP_AIRTABLE_ACCESS_TOKEN || ""
          }`,
          "Content-Type": "application/json",
        },
      }
    );
    const { data } = response;
    const records = data.records.map(
      (record: { fields: IAirtableValues }) => record.fields
    );
    return records;
  } catch (error) {
    // do nothing
    return undefined;
  }
};

export const useAnnouncement = (): IAirtableValues[] => {
  const { data: values } = useSWR("feature", fetchAnnouncement);

  return values || [];
};
