/* eslint-disable no-nested-ternary */
import currency from "currency.js";
import { useAnimation } from "framer-motion";
import {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SubmitHandler, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  GetAccount200ResponsePositionsInner,
  InstrumentTypeResponse,
  OrderTypeResponse,
  PostOrders200Response,
  SideResponse,
  Stop,
  TimeInForce,
} from "../../../codegen-api";
import { COLORS } from "../../../constants/design/colors";
import { AccountStateEnum, AuthContext } from "../../../contexts/AuthContext";
import { ConnectWalletContext } from "../../../contexts/ConnectWalletContext";
import { MarketInstrumentContext } from "../../../contexts/MarketInstrumentContext";
import { IPerpsMarket } from "../../../contexts/MarketInstrumentContext/useGetMarkets";
import { FormValidatorKeysEnum } from "../../../enums/form";
import { useGetAccount } from "../../../hooks/api/account/useGetAccount";
import { useOrder } from "../../../hooks/api/order/useOrder";
import { useToast } from "../../../hooks/toast";
import usePersistentState from "../../../hooks/usePersistentState";
import { useSFX } from "../../../hooks/useSFX";
import { useMarkPriceWSS } from "../../../hooks/wss/useMarkPriceWSS";
import usePositionsWSS from "../../../hooks/wss/usePositionsWSS";
import { ICreateOrderBody } from "../../../interfaces/Order";
import { IPriceSize } from "../../../interfaces/Orderbook";
import { IStat } from "../../../interfaces/Toast";
import { IPositionInfo, ITradeInfo } from "../../../interfaces/TradeInfo";
import { AssetResponse } from "../../../utils/asset";
import {
  getAssetLogo,
  isPrelaunchWithBoostedVolume,
} from "../../../utils/asset/assets";
import {
  formatSizePrecision,
  roundToStepSize,
  roundUpToPrecision,
} from "../../../utils/format";
import { getContractPriceStep } from "../../../utils/instruments";
import { getStopOrderName } from "../../../utils/order";
import { getValueOfContractsAmount } from "../../../utils/orderbook";
import {
  ToastEnum,
  ToastStatusEnum,
  getToastTitleForInstrument,
} from "../../../utils/toast";
import { Button, ButtonThemeEnum } from "../../Buttons/styles";
import TradeFormTradingTooltip from "../../shared/Tooltips/TradeFormTradingTooltip";
import { BoostTitle, BoostValueWrapper } from "../style";
import {
  IPerpsFormFieldValues,
  PerpFormFieldKeyEnum,
  usePerpsForm,
} from "./components/form";
import { useOrderbookWSS } from "../../../hooks/wss/useOrderbookWSS";
import useRewards from "../../../hooks/api/rewards/useRewards";
import DesktopComponent from "./DesktopComponent";
import MobileComponent from "./MobileComponent";
import { useMarginRequirements } from "../../../hooks/api/margin/useMarginRequirements";
import { Spinner } from "../../shared/Spinner";

export type IPerpsFormDefaultValues = {
  amount?: string;
  price?: string;
  side?: SideResponse;
};

export interface ITradeModalProps {
  perpInstrument?: IPerpsMarket;
  mobileMode?: boolean;
  onClose?: () => void;
  defaultValues?: IPerpsFormDefaultValues;
  onOrderbookRowClick?: (
    price: string,
    amount: string,
    side: SideResponse
  ) => void;
  showTradeForm?: boolean;
  setShowTradeForm?: (show: boolean) => void;
}

function PerpsTradeForm({
  perpInstrument,
  mobileMode,
  onClose,
  defaultValues,
  onOrderbookRowClick,
  showTradeForm,
  setShowTradeForm,
}: PropsWithChildren<ITradeModalProps>) {
  const { t } = useTranslation();
  const { t: apiError } = useTranslation("apiErrors");
  const { t: formTranslations } = useTranslation("app", {
    keyPrefix: "TradeForm.PerpsTradeForm.Form",
  });
  const { t: commonFormTranslations } = useTranslation("app", {
    keyPrefix: "TradeForm.Common",
  });
  const { t: perpTradeFormTranslations } = useTranslation("app", {
    keyPrefix: "TradeForm.PerpsTradeForm.PerpsTradeForm",
  });
  const modalRef = useRef<HTMLDivElement>(null);
  const { setShowConnectModal } = useContext(ConnectWalletContext);
  const { playSound } = useSFX();
  const { accountSigningKeyState, account } = useContext(AuthContext);
  const { showOnboarding, data: accountData } = useGetAccount();
  const { activePerpMarkets } = useContext(MarketInstrumentContext);
  const { createOrder } = useOrder();
  const { addToast, addErrorToast } = useToast();
  const { prelaunchBoost } = useRewards();

  const animControls = useAnimation();

  // STATES
  const {
    tradeUseUSDCTerms,
    setTradeUseUSDCTerms,
    formDefaults,
    setFormDefaults,
  } = usePersistentState();
  const [orderDirection, setOrderDirection] = useMemo(() => {
    const set = (side: SideResponse) => setFormDefaults({ side });

    if (formDefaults?.side) {
      return [formDefaults.side, set];
    }
    return [SideResponse.Buy, set];
  }, [formDefaults?.side, setFormDefaults]);

  // only update order direction once for mobile
  useEffect(() => {
    if (defaultValues?.side) {
      setOrderDirection(defaultValues.side);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues?.side]);

  const [orderType, setOrderType] = useMemo(() => {
    const set = (type: OrderTypeResponse) =>
      setFormDefaults({ orderType: type });
    if (formDefaults?.orderType) {
      return [formDefaults.orderType, set];
    }
    return [OrderTypeResponse.Market, set];
  }, [formDefaults?.orderType, setFormDefaults]);

  const [isStop, setIsStop] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reduceOnly, setReduceOnly] = useState(false);
  const [tpslForOrder, setTpslForOrder] = useState(false);
  const [tifType, setTifType] = useState<TimeInForce>(TimeInForce.Gtc);
  const [tifDropdown, setTifDropdown] = useState(false);
  const [defaultValuesTriggered, setDefaultValuesTriggered] =
    useState<boolean>(false);

  // WEBSOCKETS
  const { positionsMap } = usePositionsWSS(perpInstrument?.instrument_name);

  const m = useMarkPriceWSS(
    perpInstrument
      ? {
          instrumentName: perpInstrument.instrument_name,
          asset: perpInstrument.underlying_asset as AssetResponse,
          derivative: InstrumentTypeResponse.Perpetual,
        }
      : undefined
  );

  const markPrice = m?.mark_price || perpInstrument?.mark_price;

  // wss
  const orderbookData = useOrderbookWSS(perpInstrument?.instrument_name);

  const dropdownValue = isStop ? `Stop-${orderType}` : "Stop";

  const currentPosition: GetAccount200ResponsePositionsInner | undefined =
    positionsMap[perpInstrument?.instrument_name || ""];

  const contractPriceStep = useMemo(
    () => getContractPriceStep(perpInstrument),
    [perpInstrument]
  );

  // FORM
  const form = usePerpsForm();

  const {
    formState: { errors, isDirty },
    setValue,
    control,
    unregister,
    trigger,
  } = form;

  // FORM FIELDS

  const triggerPrice = useWatch({
    control,
    name: PerpFormFieldKeyEnum.TRIGGER_PRICE,
  });
  const leverage = useWatch({ control, name: PerpFormFieldKeyEnum.LEVERAGE });
  const amount = useWatch({ control, name: PerpFormFieldKeyEnum.AMOUNT });
  const price = useWatch({ control, name: PerpFormFieldKeyEnum.PRICE });

  const { data: marginData, isLoading: marginDataLoading } =
    useMarginRequirements(
      amount && perpInstrument?.instrument_id
        ? {
            is_buy: orderDirection === SideResponse.Buy,
            limit_price: String(Math.round(Number(price) || 0)),
            amount,
            instrument_id: Number(perpInstrument.instrument_id),
          }
        : undefined
    );

  // If there are default values, or is prelaunch
  // first set the order type to limit
  useEffect(() => {
    if (
      defaultValues?.amount ||
      defaultValues?.price ||
      Boolean(perpInstrument?.pre_launch)
    ) {
      setOrderType(OrderTypeResponse.Limit);
      setDefaultValuesTriggered(true);
    }
    // setOrderType is removed from dependency so that setOrderType
    // does not recall this function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues, perpInstrument?.pre_launch]);

  // after order type set to limit with default values
  // set the default values to form
  useEffect(() => {
    if (defaultValuesTriggered && defaultValues) {
      if (defaultValues.amount) {
        setValue(PerpFormFieldKeyEnum.AMOUNT, defaultValues.amount, {
          shouldValidate: true,
        });
      }
      if (defaultValues.price) {
        setValue(PerpFormFieldKeyEnum.PRICE, defaultValues.price, {
          shouldValidate: true,
        });
      }
    }
  }, [defaultValues, defaultValuesTriggered, setValue]);

  const [minOrderValue, maxOrderValue] = useMemo(() => {
    if (activePerpMarkets && perpInstrument) {
      const assetMarketData = activePerpMarkets.find(
        (assetData) =>
          assetData.underlying_asset === perpInstrument?.underlying_asset
      );

      return [
        assetMarketData?.min_order_value,
        assetMarketData?.max_order_value,
      ];
    }
    return [undefined, undefined];
  }, [activePerpMarkets, perpInstrument]);

  // On unmount, unregister from form
  useEffect(
    () => () => {
      Object.values(PerpFormFieldKeyEnum).forEach((k) => {
        unregister(k);
      });
    },
    [unregister]
  );

  // Reset tp/sl for order values when checkbox unmarked
  useEffect(() => {
    if (!tpslForOrder) {
      setValue(PerpFormFieldKeyEnum.ORDER_TP_TRIGGER_PRICE, "", {
        shouldValidate: true,
      });
      setValue(PerpFormFieldKeyEnum.ORDER_SL_TRIGGER_PRICE, "", {
        shouldValidate: true,
      });
    }
  }, [setValue, tpslForOrder]);

  const orderValueTooSmall =
    errors?.amount?.type === FormValidatorKeysEnum.orderValueTooSmall;

  const orderValueTooLarge =
    errors?.amount?.type === FormValidatorKeysEnum.orderValueTooLarge;

  const notEnoughBalanceError =
    errors?.amount?.type === FormValidatorKeysEnum.notEnoughBalance;

  // Updates default values
  useEffect(() => {
    if (defaultValues?.amount) {
      setValue(PerpFormFieldKeyEnum.AMOUNT, defaultValues.amount, {
        shouldValidate: true,
      });
    }
  }, [defaultValues, setValue]);

  // TRIGGERS WHEN VALUES UPDATED

  // Update whenever reduce only checkbox is changed
  useEffect(() => {
    if (isDirty && amount) {
      trigger(PerpFormFieldKeyEnum.AMOUNT);
    }
  }, [reduceOnly, orderDirection, trigger, isDirty, amount, tradeUseUSDCTerms]);

  useEffect(() => {
    if (isDirty) {
      trigger(PerpFormFieldKeyEnum.LEVERAGE);
    }
  }, [amount, isDirty, trigger]);

  // Limit triggers
  useEffect(() => {
    if (amount && price) {
      trigger(PerpFormFieldKeyEnum.PRICE);
      trigger(PerpFormFieldKeyEnum.AMOUNT);
    }
  }, [amount, price, trigger]);

  // Stop triggers
  useEffect(() => {
    if (triggerPrice) {
      trigger(PerpFormFieldKeyEnum.TRIGGER_PRICE);
    }
    if (price) {
      trigger(PerpFormFieldKeyEnum.PRICE);
    }
  }, [trigger, triggerPrice, orderDirection, orderType, price]);

  // TPSL for order triggers
  useEffect(() => {
    if (price) {
      trigger(PerpFormFieldKeyEnum.PRICE);
    }
    if (tpslForOrder) {
      trigger(PerpFormFieldKeyEnum.ORDER_TP_TRIGGER_PRICE);
      trigger(PerpFormFieldKeyEnum.ORDER_SL_TRIGGER_PRICE);
    }
  }, [orderDirection, price, tpslForOrder, trigger]);

  // Reduce only is disabled if theres no position, or position === orderDirection
  const reduceOnlyNotAllowed = useMemo(
    () => !currentPosition || currentPosition.side === orderDirection,
    [currentPosition, orderDirection]
  );

  const accountLeverage = useMemo(
    () =>
      accountData?.leverages?.find(
        (lev) => lev.instrument_id === perpInstrument?.instrument_id
      )?.leverage,
    [accountData?.leverages, perpInstrument?.instrument_id]
  );

  // Account max leverage takes precedence before asset max leverage
  const maxLeverage = useMemo(() => {
    const lev = accountLeverage
      ? Number(accountLeverage)
      : Number(perpInstrument?.max_leverage);
    return lev || 0;
  }, [accountLeverage, perpInstrument?.max_leverage]);

  const buyingPower = useMemo(() => {
    if (accountData) {
      return Number(accountData.available_balance) * maxLeverage;
    }
    return 0;
  }, [accountData, maxLeverage]);

  const replacePlaceOrderButton = useMemo(() => {
    if (accountSigningKeyState !== AccountStateEnum.OK || showOnboarding) {
      return (
        <Button
          fullWidth
          type="button"
          buttonTheme={ButtonThemeEnum.HIGHLIGHT}
          onClick={() => setShowConnectModal(true)}
        >
          {accountSigningKeyState === AccountStateEnum.REQUIRE_PASSWORD
            ? commonFormTranslations("unlock_trading")
            : accountSigningKeyState === AccountStateEnum.OK
            ? commonFormTranslations("continue_onboarding")
            : account
            ? commonFormTranslations("complete_sign_in")
            : commonFormTranslations("connect_wallet")}
        </Button>
      );
    }
    return undefined;
  }, [
    accountSigningKeyState,
    account,
    commonFormTranslations,
    setShowConnectModal,
    showOnboarding,
  ]);

  const showOrderCreatedAnimation = useCallback(async () => {
    await animControls.start({
      opacity: 1,
      scale: 1,
      transition: { duration: 0.6 },
    });
    await animControls.start({
      opacity: 0,
      scale: 0,
      transition: {
        delay: 3,
        duration: 0.5,
      },
    });
  }, [animControls]);

  const onCreateOrder = useCallback(
    async (
      order: ICreateOrderBody,
      tpForOrder?: ICreateOrderBody,
      slForOrder?: ICreateOrderBody
    ) => {
      if (!perpInstrument) {
        return;
      }

      try {
        setIsLoading(true);
        const response = await createOrder(order, false);

        let tpForOrderResponse: PostOrders200Response | undefined;
        let slForOrderResponse: PostOrders200Response | undefined;

        if (response.order_id) {
          if (tpForOrder) {
            const tpForOrderWithParentOrderId = {
              ...tpForOrder,
              parentOrderId: response.order_id,
            };
            tpForOrderResponse = await createOrder(
              tpForOrderWithParentOrderId,
              false
            );
          }
          if (slForOrder) {
            const slForOrderWithParentOrderId = {
              ...slForOrder,
              parentOrderId: response.order_id,
            };
            slForOrderResponse = await createOrder(
              slForOrderWithParentOrderId,
              false
            );
          }

          playSound("order_placed");
          const stats: IStat[] = response.stop
            ? [
                {
                  label: commonFormTranslations("contracts"),
                  value: Number(response.amount).toFixed(
                    contractPriceStep.amount_precision
                  ),
                },
                {
                  label: perpTradeFormTranslations("trigger_price"),
                  value: currency(response.trigger || 0, {
                    precision:
                      getContractPriceStep(perpInstrument).price_precision,
                  }).format(),
                },
              ]
            : [
                {
                  label:
                    orderDirection === SideResponse.Buy
                      ? commonFormTranslations("buy_amount")
                      : commonFormTranslations("sell_amount"),
                  value: (
                    <span
                      style={{
                        color:
                          orderDirection === SideResponse.Buy
                            ? COLORS.positive.one
                            : COLORS.negative.one,
                      }}
                    >
                      {Number(order.amount).toFixed(
                        contractPriceStep.amount_precision
                      ) || (0).toFixed(contractPriceStep.amount_precision)}
                    </span>
                  ),
                },
              ];

          // Add limit price for limit orders,
          // Add reduce only for market orders
          if (order.orderType === OrderTypeResponse.Limit) {
            stats.push({
              label: commonFormTranslations("limit_price"),
              value: currency(order.price || 0, {
                precision: getContractPriceStep(perpInstrument).price_precision,
              }).format(),
            });
          } else {
            stats.push({
              label: perpTradeFormTranslations("reduce_only"),
              value: order.reduceOnly
                ? commonFormTranslations("yes")
                : commonFormTranslations("no"),
            });
          }

          if (tpForOrderResponse) {
            stats.push({
              label: perpTradeFormTranslations("take_profit"),
              value: currency(tpForOrderResponse.trigger || 0, {
                precision: getContractPriceStep(perpInstrument).price_precision,
              }).format(),
            });
          }
          if (slForOrderResponse) {
            stats.push({
              label: perpTradeFormTranslations("stop_loss"),
              value: currency(slForOrderResponse.trigger || 0, {
                precision: getContractPriceStep(perpInstrument).price_precision,
              }).format(),
            });
          }

          // Only show immediate toast if is market order.
          // Else just show order placed
          addToast(
            {
              type: ToastEnum.INFO,
              icon: getAssetLogo(
                perpInstrument.underlying_asset as AssetResponse
              ) as string,
              header: (
                <p>
                  {getToastTitleForInstrument(
                    InstrumentTypeResponse.Perpetual,
                    perpInstrument.instrument_name
                  )}
                </p>
              ),
              subheader: (
                <span
                  style={{
                    color:
                      orderDirection === SideResponse.Buy
                        ? COLORS.positive.one
                        : COLORS.negative.one,
                  }}
                >
                  {response.stop
                    ? `${response.side} ${getStopOrderName(
                        t,
                        response.order_type,
                        true
                      )} - Placed`
                    : `${response.order_type} ${response.side}`}
                </span>
              ),
              stats,
              status: ToastStatusEnum.SUCCESS,
            },
            4000
          );
          showOrderCreatedAnimation();
          setValue(PerpFormFieldKeyEnum.AMOUNT, "", {
            shouldValidate: true,
          });
          setValue(PerpFormFieldKeyEnum.LEVERAGE, "", {
            shouldValidate: true,
          });
          onClose?.();
        }
      } catch (error: any) {
        let errorTitle = "";
        if (order.orderType === OrderTypeResponse.Market) {
          errorTitle =
            orderDirection === SideResponse.Buy
              ? commonFormTranslations("market_buy_failed")
              : commonFormTranslations("market_sell_failed");
        } else {
          errorTitle =
            orderDirection === SideResponse.Buy
              ? commonFormTranslations("limit_buy_failed")
              : commonFormTranslations("limit_sell_failed");
        }
        addErrorToast(
          <p>{errorTitle}</p>,
          apiError(error.message) || commonFormTranslations("place_order_again")
        );
      } finally {
        setIsLoading(false);
      }
    },
    [
      perpInstrument,
      createOrder,
      playSound,
      commonFormTranslations,
      contractPriceStep.amount_precision,
      perpTradeFormTranslations,
      orderDirection,
      addToast,
      t,
      showOrderCreatedAnimation,
      setValue,
      onClose,
      addErrorToast,
      apiError,
    ]
  );

  const positionInfo = useMemo<IPositionInfo[]>(() => {
    const posInfo: IPositionInfo[] = [
      {
        title: commonFormTranslations("margin_balance"),
        value: accountData?.available_balance
          ? currency(accountData.available_balance).format()
          : "---",
        side: undefined,
      },
      {
        title: commonFormTranslations("buying_power"),
        value: buyingPower ? currency(buyingPower).format() : "---",
        side: undefined,
        warningOrError: notEnoughBalanceError ? "error" : undefined,
        showErrorIcon: true,
      },
      {
        title: `${formTranslations("position")} (${
          perpInstrument?.underlying_asset as AssetResponse
        })`,
        value: currentPosition
          ? formatSizePrecision(
              Number(currentPosition.amount),
              contractPriceStep.amount_precision
            )
          : "---",
        side: currentPosition?.side,
      },
    ];
    return posInfo;
  }, [
    commonFormTranslations,
    formTranslations,
    contractPriceStep.amount_precision,
    accountData?.available_balance,
    buyingPower,
    notEnoughBalanceError,
    perpInstrument?.underlying_asset,
    currentPosition,
  ]);

  const minimumUSDCValueForValidContractSize = useMemo(() => {
    const { amount_step, amount_precision } = contractPriceStep;
    if (orderbookData) {
      // Market order
      const bestBidOrAskPrice = (
        orderDirection === SideResponse.Buy
          ? orderbookData.asks
          : orderbookData.bids
      )?.[0]?.[0];
      // Round UP to precision
      return roundUpToPrecision(
        amount_step * Number(bestBidOrAskPrice || 0),
        amount_precision
      );
    }
    if (price) {
      // Round UP to precision
      return roundUpToPrecision(amount_step * Number(price), amount_precision);
    }
    return 0;
  }, [contractPriceStep, orderDirection, orderbookData, price]);

  // VALIDATIONS
  // Limit price validation for stop orders
  const limitPriceValidateFn = useMemo(() => {
    if (isStop) {
      // If stop limit and buy, limit price >= trigger price
      if (orderDirection === SideResponse.Buy) {
        return {
          [FormValidatorKeysEnum.limitPriceAboveTriggerPrice]: (v: string) =>
            parseFloat(v) >= Number(triggerPrice),
          [FormValidatorKeysEnum.limitPriceBelowTriggerPrice]: () => true,
        };
      }
      return {
        [FormValidatorKeysEnum.limitPriceAboveTriggerPrice]: () => true,
        [FormValidatorKeysEnum.limitPriceBelowTriggerPrice]: (v: string) =>
          parseFloat(v) <= Number(triggerPrice),
      };
    }
    // if not stops, dont do anything
    return {
      [FormValidatorKeysEnum.limitPriceAboveTriggerPrice]: () => true,
      [FormValidatorKeysEnum.limitPriceBelowTriggerPrice]: () => true,
    };
  }, [isStop, orderDirection, triggerPrice]);

  // Stop order validation
  const triggerPriceValidateFn = useMemo(() => {
    if (!isStop) {
      return {
        [FormValidatorKeysEnum.triggerPriceBelowMark]: () => true,
        [FormValidatorKeysEnum.triggerPriceAboveMark]: () => true,
      };
    }

    // If BUY STOP LOSS or SELL TAKE PROFIT, trigger price must be higher than mark
    if (orderDirection === SideResponse.Buy) {
      return {
        [FormValidatorKeysEnum.triggerPriceBelowMark]: () => true,
        [FormValidatorKeysEnum.triggerPriceAboveMark]: (v: string) =>
          parseFloat(v) >= Number(markPrice),
      };
    }
    // If SELL STOP LOSS or BUY TAKE PROFIT, trigger price must be lower than mark
    return {
      [FormValidatorKeysEnum.triggerPriceBelowMark]: (v: string) =>
        parseFloat(v) <= Number(markPrice),
      [FormValidatorKeysEnum.triggerPriceAboveMark]: () => true,
    };
  }, [isStop, markPrice, orderDirection]);

  // TPSL during order validations
  const triggerOrderStopValidateFn = useCallback(
    (stop: Stop) => {
      const tpslOrderDirection =
        orderDirection === SideResponse.Buy
          ? SideResponse.Sell
          : SideResponse.Buy;
      if (!stop) {
        return {
          [FormValidatorKeysEnum.triggerPriceBelowMark]: () => true,
          [FormValidatorKeysEnum.triggerPriceAboveMark]: () => true,
        };
      }

      // If BUY STOP LOSS or SELL TAKE PROFIT, trigger price must be higher than mark
      if (
        (tpslOrderDirection === SideResponse.Buy && stop === Stop.StopLoss) ||
        (tpslOrderDirection === SideResponse.Sell && stop === Stop.TakeProfit)
      ) {
        return {
          [FormValidatorKeysEnum.triggerPriceBelowMark]: () => true,
          [FormValidatorKeysEnum.triggerPriceAboveMark]: (v: string) =>
            !v || parseFloat(v) >= Number(markPrice),
        };
      }
      // If SELL STOP LOSS or BUY TAKE PROFIT, trigger price must be lower than mark
      return {
        [FormValidatorKeysEnum.triggerPriceBelowMark]: (v: string) =>
          !v || parseFloat(v) <= Number(markPrice),
        [FormValidatorKeysEnum.triggerPriceAboveMark]: () => true,
      };
    },
    [markPrice, orderDirection]
  );

  const customError = useMemo(
    () =>
      tradeUseUSDCTerms &&
      amount &&
      Number(amount) < minimumUSDCValueForValidContractSize
        ? formTranslations("minimum_size_error", {
            size: minimumUSDCValueForValidContractSize,
            asset: "USDC",
          })
        : undefined,
    [
      amount,
      formTranslations,
      minimumUSDCValueForValidContractSize,
      tradeUseUSDCTerms,
    ]
  );

  const getRealContractSize = useCallback(
    (amt: string, isUSD?: boolean) => {
      if (isUSD) {
        const stepSize = contractPriceStep.amount_step;
        const precision = contractPriceStep.amount_precision;

        // if limit, use the limit price
        if (orderType === OrderTypeResponse.Limit) {
          return roundToStepSize(
            Number(amt || 0) / Number(price),
            stepSize,
            precision
          );
        }

        // if asset is USD and market order,
        // go through OB to figure out how many contracts we can buy
        if (orderbookData) {
          let usdAmtToFill = Number(amt || 0);
          const bidsAsks =
            orderDirection === SideResponse.Buy
              ? orderbookData.asks
              : orderbookData.bids;
          const totalSize = bidsAsks?.reduce((prev, ask) => {
            if (!usdAmtToFill) {
              return prev;
            }

            const [askPrice, size] = ask;

            // Size per USD
            const totalCost = Number(askPrice) * Number(size);

            // Buying more than this level, go to next level
            if (usdAmtToFill > totalCost) {
              usdAmtToFill -= totalCost;
              return prev + Number(size);
            }

            // Not enough to buy the entire level, calculate contract
            const sizeAbleToBuy = usdAmtToFill / Number(askPrice);
            usdAmtToFill = 0;
            return prev + sizeAbleToBuy;
          }, 0);

          return roundToStepSize(totalSize || 0, stepSize, precision);
        }
      }

      // if asset is underlying, just use size
      return Number(amt || 0);
    },
    [
      contractPriceStep.amount_precision,
      contractPriceStep.amount_step,
      orderDirection,
      orderType,
      orderbookData,
      price,
    ]
  );

  // get the total usd amount based on size
  const getRealContractUSDAmount = useCallback(
    (size: string) => {
      // if limit, use the limit price
      if (orderType === OrderTypeResponse.Limit) {
        return Number(size || 0) * Number(price);
      }

      // else traverse orderbook
      if (orderbookData) {
        let sizeToFill = Number(size || 0);
        const bidsAsks =
          orderDirection === SideResponse.Buy
            ? orderbookData.asks
            : orderbookData.bids;
        const totalSize = bidsAsks?.reduce((prev, ask) => {
          if (!sizeToFill) {
            return prev;
          }

          const [askPrice, askSize] = ask;

          const sizeInUnderlying = Number(askSize);

          // Size per USD
          const totalCost = Number(askPrice) * Number(askSize);

          // Buying more than this level, go to next level
          if (sizeToFill > sizeInUnderlying) {
            sizeToFill -= sizeInUnderlying;
            return prev + Number(totalCost);
          }

          // Not enough to buy the entire level, calculate contract
          const usdAbleToBuy = sizeToFill * Number(askPrice);
          sizeToFill = 0;
          return prev + usdAbleToBuy;
        }, 0);

        return totalSize || 0;
      }

      return 0;
    },
    [orderDirection, orderType, orderbookData, price]
  );

  const calculateTotalValueWithSize = useCallback(
    (amountStr: string, isUSDC?: boolean) => {
      let total = 0;

      const isUSD = isUSDC || tradeUseUSDCTerms;

      const realSize = getRealContractSize(amountStr, isUSD);

      // Limit order
      if (orderType === OrderTypeResponse.Limit) {
        return realSize * Number(price);
      }

      if (isStop) {
        // If is a stop order, calculate total using trigger price instead
        total = realSize * (Number(triggerPrice) || 0);
      } else {
        total = getValueOfContractsAmount(
          orderDirection,
          orderDirection === SideResponse.Buy
            ? (orderbookData?.asks as IPriceSize[]) || []
            : (orderbookData?.bids as IPriceSize[]) || [],
          realSize
        );
      }

      return total;
    },
    [
      tradeUseUSDCTerms,
      getRealContractSize,
      orderType,
      isStop,
      price,
      triggerPrice,
      orderDirection,
      orderbookData?.asks,
      orderbookData?.bids,
    ]
  );

  const realContractSize = getRealContractSize(amount, tradeUseUSDCTerms);

  const tradeInfo = useMemo<ITradeInfo[]>(() => {
    // ================ MARKET ORDERS ================
    // MARKET BUY/SELL ORDER
    const totalValue = calculateTotalValueWithSize(amount);
    return [
      {
        title: commonFormTranslations("required_margin"),
        value: marginDataLoading ? (
          <Spinner />
        ) : marginData?.initial_margin !== undefined ? (
          currency(marginData.initial_margin, {
            precision: getContractPriceStep(perpInstrument).price_precision,
          }).format()
        ) : (
          "---"
        ),
        warningOrError: notEnoughBalanceError ? "error" : undefined,
      },
      {
        title: commonFormTranslations("order_value"),
        value: totalValue
          ? currency(totalValue, {
              precision: getContractPriceStep(perpInstrument).price_precision,
            }).format()
          : "---",
        warningOrError:
          notEnoughBalanceError || orderValueTooSmall || orderValueTooLarge
            ? "error"
            : undefined,
      },
      {
        title: commonFormTranslations("liquidation_price"),
        value: marginDataLoading ? (
          <Spinner />
        ) : marginData?.liquidation_price !== undefined ? (
          currency(marginData.liquidation_price, {
            precision: getContractPriceStep(perpInstrument).price_precision,
          }).format()
        ) : (
          "---"
        ),
        warningOrError:
          notEnoughBalanceError || orderValueTooSmall || orderValueTooLarge
            ? "error"
            : undefined,
      },
    ];
  }, [
    calculateTotalValueWithSize,
    amount,
    commonFormTranslations,
    perpInstrument,
    notEnoughBalanceError,
    orderValueTooSmall,
    orderValueTooLarge,
    marginData,
    marginDataLoading,
  ]);

  const feeStructure = accountData?.fee_structures?.find(
    (f) =>
      f.asset === perpInstrument?.underlying_asset &&
      f.instrument_type === InstrumentTypeResponse.Perpetual
  );

  const bottomTradeInfo = useMemo<ITradeInfo[] | undefined>(() => {
    if (
      perpInstrument?.pre_launch &&
      !isPrelaunchWithBoostedVolume(perpInstrument?.underlying_asset)
    ) {
      return undefined;
    }
    if (
      perpInstrument?.pre_launch &&
      isPrelaunchWithBoostedVolume(perpInstrument?.underlying_asset)
    ) {
      const totalVolume = calculateTotalValueWithSize(amount);
      const boostMultiplier = prelaunchBoost || 0;
      const boostedVolume = totalVolume * boostMultiplier;
      return [
        {
          key: "Boosted Volume",
          title: (
            <BoostTitle>
              <div>{perpTradeFormTranslations("boosted_volume")}</div>
              <TradeFormTradingTooltip
                farmBoostAfterTrade={boostMultiplier}
                boostedVolume={boostedVolume}
                isPrelaunch
              />
            </BoostTitle>
          ),
          value: (
            <BoostValueWrapper>
              {boostedVolume ? currency(boostedVolume).format() : "---"}
            </BoostValueWrapper>
          ),
        },
      ];
    }
    return undefined;
  }, [
    amount,
    calculateTotalValueWithSize,
    perpInstrument?.pre_launch,
    perpInstrument?.underlying_asset,
    perpTradeFormTranslations,
    prelaunchBoost,
  ]);

  const verifyNotEnoughBalance = useCallback(
    (amt: string) => {
      const size = getRealContractSize(amount, tradeUseUSDCTerms);
      if (
        currentPosition &&
        currentPosition.side !== orderDirection &&
        Number(currentPosition.amount) >= size
      ) {
        return true;
      }
      return buyingPower >= calculateTotalValueWithSize(amt);
    },
    [
      amount,
      buyingPower,
      calculateTotalValueWithSize,
      currentPosition,
      getRealContractSize,
      tradeUseUSDCTerms,
      orderDirection,
    ]
  );

  /**
   * If priceValue is not provided, use totalValue instead.
   * If is market order, pass in undefined for priceValue
   */
  const updateLeverage = useCallback(
    (orderSize: number, isUSDC: boolean, priceValue?: number) => {
      if (accountData?.available_balance) {
        if (priceValue) {
          const value = isUSDC ? orderSize : priceValue * orderSize;
          const lvrg = Math.min(
            value / Number(accountData.available_balance),
            maxLeverage
          );
          setValue(PerpFormFieldKeyEnum.LEVERAGE, lvrg.toFixed(2));
        }
      }
    },
    [accountData, maxLeverage, setValue]
  );

  const updateOrderSize = useCallback(
    (leverageValue: number, disableLeverageUpdate?: boolean) => {
      if (accountData?.available_balance) {
        const amountUSDC =
          Number(accountData.available_balance) * leverageValue;

        // Round to allowed order size, and update leverage value
        const value = tradeUseUSDCTerms
          ? amountUSDC
          : getRealContractSize(String(amountUSDC), true);

        const stepSize = tradeUseUSDCTerms
          ? contractPriceStep.price_step
          : contractPriceStep.amount_step;
        const precision = tradeUseUSDCTerms
          ? contractPriceStep.price_precision
          : contractPriceStep.amount_precision;
        const newValue = roundToStepSize(value, stepSize, precision);
        setValue(PerpFormFieldKeyEnum.AMOUNT, newValue.toFixed(2));

        if (!disableLeverageUpdate) {
          updateLeverage(
            newValue,
            tradeUseUSDCTerms,
            price ? Number(price) : undefined
          );
        }
      }
    },
    [
      accountData?.available_balance,
      tradeUseUSDCTerms,
      getRealContractSize,
      contractPriceStep.price_step,
      contractPriceStep.amount_step,
      contractPriceStep.price_precision,
      contractPriceStep.amount_precision,
      setValue,
      updateLeverage,
      price,
    ]
  );

  const updateReduceOnlyOrderSize = useCallback(
    (size: number) => {
      if (accountData?.available_balance) {
        const value = tradeUseUSDCTerms
          ? getRealContractUSDAmount(String(size))
          : size;

        const stepSize = tradeUseUSDCTerms
          ? contractPriceStep.price_step
          : contractPriceStep.amount_step;
        const precision = tradeUseUSDCTerms
          ? contractPriceStep.price_precision
          : contractPriceStep.amount_precision;
        const newValue = roundToStepSize(value, stepSize, precision);
        setValue(PerpFormFieldKeyEnum.AMOUNT, newValue.toFixed(2));
      }
    },
    [
      accountData?.available_balance,
      tradeUseUSDCTerms,
      getRealContractUSDAmount,
      contractPriceStep.price_step,
      contractPriceStep.amount_step,
      contractPriceStep.price_precision,
      contractPriceStep.amount_precision,
      setValue,
    ]
  );

  // update leverage when orderType/price changes
  useEffect(() => {
    if (leverage) {
      setValue(PerpFormFieldKeyEnum.LEVERAGE, leverage);
      updateOrderSize(Number(leverage), true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderType, price]);

  const stopType = useMemo(() => {
    if (!isStop) {
      return undefined;
    }
    if (orderDirection === SideResponse.Buy) {
      return Number(triggerPrice) > Number(markPrice)
        ? Stop.StopLoss
        : Stop.TakeProfit;
    }
    return Number(triggerPrice) > Number(markPrice)
      ? Stop.TakeProfit
      : Stop.StopLoss;
  }, [isStop, markPrice, orderDirection, triggerPrice]);

  const submitOrder = useCallback<SubmitHandler<IPerpsFormFieldValues>>(
    async (values) => {
      const size = getRealContractSize(values.amount, tradeUseUSDCTerms);
      if (perpInstrument && size) {
        const order: ICreateOrderBody =
          orderType === OrderTypeResponse.Market
            ? {
                instrument: Number(perpInstrument.instrument_id),
                amount: String(size),
                side: orderDirection,
                orderType: OrderTypeResponse.Market,
                stop: stopType,
                trigger: isStop ? values.triggerPrice : undefined,
                reduceOnly,
                isolatedMargin:
                  values.marginType === "isolated"
                    ? `${(
                        (calculateTotalValueWithSize(values.amount) /
                          Number(accountLeverage)) *
                        1e6
                      ).toFixed(0)}`
                    : undefined,
              }
            : {
                instrument: Number(perpInstrument.instrument_id),
                amount: String(size),
                side: orderDirection,
                price: values.price,
                orderType: OrderTypeResponse.Limit,
                stop: stopType,
                trigger: isStop ? values.triggerPrice : undefined,
                reduceOnly,
                timeInForce: tifType,
                isolatedMargin:
                  values.marginType === "isolated"
                    ? `${(
                        (calculateTotalValueWithSize(values.amount) /
                          Number(accountLeverage)) *
                        1000000
                      ).toFixed(0)}`
                    : undefined,
              };
        const tpslOrderDirection =
          orderDirection === SideResponse.Buy
            ? SideResponse.Sell
            : SideResponse.Buy;
        const tpForOrder: ICreateOrderBody | undefined =
          values.orderTpTriggerPrice && tpslForOrder
            ? {
                instrument: Number(perpInstrument.instrument_id),
                amount: String(size),
                side: tpslOrderDirection,
                orderType: OrderTypeResponse.Market,
                stop: Stop.TakeProfit,
                trigger: values.orderTpTriggerPrice,
                reduceOnly: true,
              }
            : undefined;
        const slForOrder: ICreateOrderBody | undefined =
          values.orderSlTriggerPrice && tpslForOrder
            ? {
                instrument: Number(perpInstrument.instrument_id),
                amount: String(size),
                side: tpslOrderDirection,
                orderType: OrderTypeResponse.Market,
                stop: Stop.StopLoss,
                trigger: values.orderSlTriggerPrice,
                reduceOnly: true,
              }
            : undefined;
        onCreateOrder(order, tpForOrder, slForOrder);
      }
    },
    [
      getRealContractSize,
      tradeUseUSDCTerms,
      perpInstrument,
      orderType,
      orderDirection,
      stopType,
      isStop,
      reduceOnly,
      calculateTotalValueWithSize,
      accountLeverage,
      tifType,
      tpslForOrder,
      onCreateOrder,
    ]
  );

  // if buy, check asks liquidity
  let insufficientLiquidity = false;

  if (orderDirection === SideResponse.Buy && orderbookData) {
    const totalAsksSize =
      orderbookData.asks?.reduce((prev, ask) => {
        const askSize = Number(ask[1] || 0);
        return prev + askSize;
      }, 0) || 0;
    if (!totalAsksSize || totalAsksSize < realContractSize) {
      insufficientLiquidity = true;
    }
  }

  // if sell, check bids liquidity
  if (orderDirection === SideResponse.Sell && orderbookData) {
    const totalBidsSize =
      orderbookData.bids?.reduce((prev, ask) => {
        const bidSize = Number(ask[1] || 0);
        return prev + bidSize;
      }, 0) || 0;
    if (!totalBidsSize || totalBidsSize < realContractSize) {
      insufficientLiquidity = true;
    }
  }

  if (mobileMode && onOrderbookRowClick) {
    return (
      <MobileComponent
        form={form}
        perpInstrument={perpInstrument}
        submitOrder={submitOrder}
        amount={amount}
        price={price}
        leverage={leverage}
        markPrice={markPrice}
        bestPrice={
          (orderDirection === SideResponse.Buy
            ? orderbookData?.bids
            : orderbookData?.asks)?.[0]?.[0]
        }
        showTradeForm={showTradeForm}
        setShowTradeForm={setShowTradeForm}
        orderDirection={orderDirection}
        setOrderDirection={setOrderDirection}
        orderType={orderType}
        setOrderType={setOrderType}
        isStop={isStop}
        setIsStop={setIsStop}
        tradeUseUSDCTerms={tradeUseUSDCTerms}
        setTradeUseUSDCTerms={setTradeUseUSDCTerms}
        tifType={tifType}
        setTifType={setTifType}
        tpslForOrder={tpslForOrder}
        setTpslForOrder={setTpslForOrder}
        tifDropdown={tifDropdown}
        setTifDropdown={setTifDropdown}
        reduceOnlyNotAllowed={reduceOnlyNotAllowed}
        reduceOnly={reduceOnly}
        currentPosition={currentPosition}
        contractPriceStep={contractPriceStep}
        verifyNotEnoughBalance={verifyNotEnoughBalance}
        isLoading={isLoading}
        setReduceOnly={setReduceOnly}
        tradeInfo={tradeInfo}
        feeStructure={feeStructure}
        positionInfo={positionInfo}
        animControls={animControls}
        customError={customError}
        minOrderValue={minOrderValue}
        maxOrderValue={maxOrderValue}
        maxLeverage={maxLeverage}
        replacePlaceOrderButton={replacePlaceOrderButton}
        accountData={accountData}
        realContractSize={realContractSize}
        onOrderbookRowClick={onOrderbookRowClick}
        calculateTotalValueWithSize={calculateTotalValueWithSize}
        getRealContractSize={getRealContractSize}
        updateLeverage={updateLeverage}
        updateOrderSize={updateOrderSize}
        updateReduceOnlyOrderSize={updateReduceOnlyOrderSize}
        insufficientLiquidity={insufficientLiquidity}
        triggerPriceValidateFn={triggerPriceValidateFn}
        limitPriceValidateFn={limitPriceValidateFn}
        triggerOrderStopValidateFn={triggerOrderStopValidateFn}
      />
    );
  }
  return (
    <DesktopComponent
      modalRef={modalRef}
      form={form}
      perpInstrument={perpInstrument}
      submitOrder={submitOrder}
      amount={amount}
      price={price}
      leverage={leverage}
      markPrice={markPrice}
      bestPrice={
        (orderDirection === SideResponse.Buy
          ? orderbookData?.bids
          : orderbookData?.asks)?.[0]?.[0]
      }
      orderDirection={orderDirection}
      setOrderDirection={setOrderDirection}
      orderType={orderType}
      setOrderType={setOrderType}
      isStop={isStop}
      setIsStop={setIsStop}
      tradeUseUSDCTerms={tradeUseUSDCTerms}
      setTradeUseUSDCTerms={setTradeUseUSDCTerms}
      tifType={tifType}
      setTifType={setTifType}
      tpslForOrder={tpslForOrder}
      setTpslForOrder={setTpslForOrder}
      tifDropdown={tifDropdown}
      setTifDropdown={setTifDropdown}
      dropdownValue={dropdownValue}
      reduceOnlyNotAllowed={reduceOnlyNotAllowed}
      reduceOnly={reduceOnly}
      currentPosition={currentPosition}
      contractPriceStep={contractPriceStep}
      verifyNotEnoughBalance={verifyNotEnoughBalance}
      isLoading={isLoading}
      setReduceOnly={setReduceOnly}
      tradeInfo={tradeInfo}
      feeStructure={feeStructure}
      positionInfo={positionInfo}
      animControls={animControls}
      bottomTradeInfo={bottomTradeInfo}
      customError={customError}
      minOrderValue={minOrderValue}
      maxOrderValue={maxOrderValue}
      maxLeverage={maxLeverage}
      replacePlaceOrderButton={replacePlaceOrderButton}
      accountData={accountData}
      realContractSize={realContractSize}
      calculateTotalValueWithSize={calculateTotalValueWithSize}
      getRealContractSize={getRealContractSize}
      updateLeverage={updateLeverage}
      updateOrderSize={updateOrderSize}
      updateReduceOnlyOrderSize={updateReduceOnlyOrderSize}
      insufficientLiquidity={insufficientLiquidity}
      triggerPriceValidateFn={triggerPriceValidateFn}
      limitPriceValidateFn={limitPriceValidateFn}
      triggerOrderStopValidateFn={triggerOrderStopValidateFn}
    />
  );
}

export default PerpsTradeForm;
