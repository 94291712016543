import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { HeaderSubText } from "../shared/SharedModalStyles/styles";
import { AevoLogoContainer, VideoView } from "./style";
import { ReactComponent as AevoLogo } from "../../assets/logo/logo_text_white.svg";
import bg from "../../assets/videos/pending.mp4";
import { COLORS } from "../../constants/design/colors";

interface IOnboardProps {
  referralCode?: string | null;
  refereeDiscount?: string;
}

function Onboard({ referralCode, refereeDiscount }: IOnboardProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "ConnectWalletModal.Onboard",
  });

  const text = useMemo(() => {
    if (referralCode && refereeDiscount) {
      return (
        <div>
          {t("ref_onboarding_desc_1")}
          <span style={{ color: COLORS.highlight.one }}>
            {" "}
            {referralCode}
          </span>.{" "}
          {t("ref_onboarding_desc_2", {
            refereeDiscount: (Number(refereeDiscount) * 100).toFixed(0),
          })}
        </div>
      );
    }
    return null;
  }, [referralCode, refereeDiscount, t]);

  return (
    <>
      <VideoView>
        <video
          autoPlay
          muted
          loop
          webkit-playsinline="true"
          playsInline
          controls={false}
        >
          <source src={bg} type="video/mp4" />
        </video>
        <AevoLogoContainer>
          <AevoLogo height={48} width={160} />
        </AevoLogoContainer>
      </VideoView>
      {Boolean(text) && <HeaderSubText>{text}</HeaderSubText>}
    </>
  );
}

export default Onboard;
