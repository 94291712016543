export enum WalletEnum {
  METAMASK = 1,
  WALLETCONNECT = 2,
  WALLETLINK = 3,
  BRAVE = 4,
}

export const WALLET_NAMES = {
  [WalletEnum.METAMASK]: "Metamask",
  [WalletEnum.WALLETCONNECT]: "Wallet Connect",
  [WalletEnum.WALLETLINK]: "Coinbase Wallet",
  [WalletEnum.BRAVE]: "Brave",
};

// This is used to check whether it's an Ethereum wallet
export const WALLET_TITLES = {
  [WalletEnum.METAMASK]: "METAMASK",
  [WalletEnum.WALLETCONNECT]: "WALLETCONNECT",
  [WalletEnum.WALLETLINK]: "WALLETLINK",
  [WalletEnum.BRAVE]: "BRAVE",
};
