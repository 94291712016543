import { Web3Provider } from "@ethersproject/providers";
import { ethers } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { Web3 } from "web3";
import { Socket, Socket__factory } from "../../codegen";
import socketABI from "../../constants/abis/Socket.json";
import { MULTICHAIN_CONTRACT_ADDRESSES } from "../../constants/addresses";
import contractAddresses from "../../constants/addresses/addresses.json";
import { ChainIdEnum } from "../../enums/chain";
import { isProduction } from "../../utils/env";
import { AEVO_RPC_URL } from "../../utils/wallet/chains";
import { useGetSocketFees } from "../api/socket/useGetSocketFees";

export const getSocketContract = (
  provider?: any,
  chainId?:
    | ChainIdEnum.OPTIMISM
    | ChainIdEnum.OPTIMISM_TESTNET
    | ChainIdEnum.ARBITRUM
    | ChainIdEnum.ARBITRUM_TESTNET
): Socket | undefined => {
  const signerOrProvider = provider?.getSigner() || provider;
  if (signerOrProvider && chainId) {
    const chainAddresses = MULTICHAIN_CONTRACT_ADDRESSES[chainId];
    if (chainAddresses?.socket) {
      return Socket__factory.connect(chainAddresses.socket, signerOrProvider);
    }
  }

  return undefined;
};

interface ISocketProps {
  provider?: Web3Provider;
  chainId?: number;
}

const l2ChainId = isProduction() ? 2999 : 11155112;
const defaultGasLimit = 1000000;

const useSocket = ({ provider, chainId }: ISocketProps) => {
  const [socket, setSocket] = useState<Socket>();

  const { getSocketFees } = useGetSocketFees();
  useEffect(() => {
    setSocket(getSocketContract(provider, chainId));
  }, [chainId, provider]);

  const estimateMinFees = useCallback(
    (collateralConnectorAddress: string) => {
      if (socket) {
        return socket
          .getMinFees(
            defaultGasLimit,
            130,
            ethers.utils.hexZeroPad("0x", 32),
            ethers.utils.hexZeroPad("0x", 32),
            l2ChainId,
            collateralConnectorAddress
          )
          .catch(() => {
            if (chainId) {
              return getSocketFees(
                l2ChainId,
                collateralConnectorAddress,
                socket.address
              );
            }
            return undefined;
          });
      }
      return undefined;
    },
    [chainId, getSocketFees, socket]
  );

  const estimateMinFeesWithdrawals = useCallback(
    (collateralConnectorAddress: string) => {
      const rpc = isProduction() ? AEVO_RPC_URL.mainnet : AEVO_RPC_URL.testnet;

      const aevoSocketAddress = isProduction()
        ? contractAddresses["aevo-mainnet"].socket
        : contractAddresses["aevo-testnet"].socket;

      const web3 = new Web3(rpc);
      const contract = new web3.eth.Contract(socketABI, aevoSocketAddress);

      return contract.methods
        .getMinFees(
          defaultGasLimit,
          130,
          ethers.utils.hexZeroPad("0x", 32),
          ethers.utils.hexZeroPad("0x", 32),
          chainId,
          collateralConnectorAddress
        )
        .call()
        .catch(() => {
          if (chainId) {
            return getSocketFees(
              chainId,
              collateralConnectorAddress,
              aevoSocketAddress
            );
          }
          return undefined;
        });
    },
    [chainId, getSocketFees]
  );

  return {
    socket,
    estimateMinFees,
    estimateMinFeesWithdrawals,
  };
};

export default useSocket;
