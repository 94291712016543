import { BigNumber, ContractTransaction, ethers } from "ethers";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  IL1StandardBridge,
  IL1StandardBridge__factory,
  L1DepositHelper,
  L1DepositHelper__factory,
} from "../../codegen";
import { CollateralAssetResponse } from "../../codegen-api";
import {
  CONTRACT_ADDRESSES,
  DepositWithdrawCollaterals,
  getCollateralERC20Addresses,
  getSocketConnectorAddress,
} from "../../constants/addresses";
import { ChainIdEnum } from "../../enums/chain";
import { ISignature } from "../../interfaces/Signing";
import { IWallet } from "../wallet/useWallet";
import useSocketVault from "./useSocketVault";
import { validateEstimatedGas } from "./shared";
import useSocketHelper from "./useSocketHelper";
import useSocket from "./useSocket";

// If insufficient limit, returns the limit.
// else, returns the valid tx
export type IDepositERC20MultichainResponse =
  | {
      insufficientLimit: BigNumber;
      tx: undefined;
    }
  | {
      insufficientLimit: undefined;
      tx: ContractTransaction;
    };

export const getL1DepositHelperContract = (
  provider?: any,
  chainId?: ChainIdEnum
): L1DepositHelper | undefined => {
  const signerOrProvider = provider?.getSigner() || provider;
  if (signerOrProvider && chainId) {
    const chainAddresses = CONTRACT_ADDRESSES[chainId];
    if (chainAddresses?.l1DepositHelper) {
      return L1DepositHelper__factory.connect(
        chainAddresses.l1DepositHelper,
        signerOrProvider
      );
    }
  }

  return undefined;
};

export const getL1BridgeContract = (
  provider?: any,
  chainId?: ChainIdEnum
): IL1StandardBridge | undefined => {
  const signerOrProvider = provider?.getSigner() || provider;
  if (signerOrProvider && chainId) {
    const chainAddresses = CONTRACT_ADDRESSES[chainId];
    if (chainAddresses?.l1StandardBridge) {
      return IL1StandardBridge__factory.connect(
        chainAddresses.l1StandardBridge,
        signerOrProvider
      );
    }
  }

  return undefined;
};

const l2GasLimit = 500000;
const l1GasLimit = 1000000;

const addAdditionalEstGasBuffer = (estGas: BigNumber) =>
  // mul 11 div 10 = multiply by 1.1 (add 10% extra buffer)
  estGas.mul(ethers.BigNumber.from(11)).div(ethers.BigNumber.from(10));
const useDeposit = (
  wallet: IWallet,
  collateral?: DepositWithdrawCollaterals
) => {
  const { account, provider, chainId } = wallet;

  const { estimateMinFees } = useSocket({ provider, chainId });
  const { socketHelper } = useSocketHelper({ provider, chainId });
  const { vault } = useSocketVault({ provider, chainId, collateral });
  const [l1DepositHelper, setL1DepositHelper] = useState<L1DepositHelper>();
  const [l1StandardBridge, setL1StandardBridge] = useState<IL1StandardBridge>();
  const [depositFees, setDepositFees] = useState<BigNumber>();

  // Returns the correct spender to approve to based on the chainId
  const approvalSpender = useMemo(() => {
    if (!chainId) {
      return undefined;
    }

    switch (chainId) {
      case ChainIdEnum.ARBITRUM:
      case ChainIdEnum.ARBITRUM_TESTNET:
      case ChainIdEnum.OPTIMISM:
      case ChainIdEnum.OPTIMISM_TESTNET:
      case ChainIdEnum.ARBITRUM_LOCAL:
      case ChainIdEnum.BASE:
        return socketHelper?.address;
      default:
        return l1StandardBridge?.address;
    }
  }, [chainId, l1StandardBridge?.address, socketHelper?.address]);

  useEffect(() => {
    setL1DepositHelper(getL1DepositHelperContract(provider, chainId));
    setL1StandardBridge(getL1BridgeContract(provider, chainId));
  }, [chainId, provider]);

  const connectorAddress = useMemo(
    () =>
      collateral
        ? getSocketConnectorAddress(collateral, "deposit", chainId)
        : undefined,
    [chainId, collateral]
  );

  useEffect(() => {
    if (connectorAddress) {
      estimateMinFees(connectorAddress)?.then((fees) => {
        setDepositFees(BigNumber.from(fees));
      });
    } else {
      setDepositFees(undefined);
    }
  }, [connectorAddress, estimateMinFees]);

  const depositERC20WithPermit = useCallback(
    async (
      l1TokenAddress: string,
      l2TokenAddress: string,
      amount: BigNumber,
      deadline: number,
      signature: ISignature,
      isYieldVault: boolean = false
    ) => {
      if (!l1DepositHelper) {
        return undefined;
      }

      // Encode the data in the expected structure
      const encodedData = ethers.utils.defaultAbiCoder.encode(
        ["tuple(bool depositToYieldVault)"],
        [{ depositToYieldVault: true }]
      );
      const dataAsHex = isYieldVault ? encodedData : [];

      const estGas = await l1DepositHelper.estimateGas.depositERC20WithPermit(
        l1TokenAddress,
        l2TokenAddress,
        amount,
        l2GasLimit,
        dataAsHex,
        deadline,
        signature.v,
        signature.r,
        signature.s
      );

      return l1DepositHelper.depositERC20WithPermit(
        l1TokenAddress,
        l2TokenAddress,
        amount,
        l2GasLimit,
        dataAsHex,
        deadline,
        signature.v,
        signature.r,
        signature.s,
        {
          gasLimit: addAdditionalEstGasBuffer(estGas),
        }
      );
    },
    [l1DepositHelper]
  );

  const depositETH = useCallback(
    async (amount: BigNumber) => {
      const { l2 } = getCollateralERC20Addresses(
        CollateralAssetResponse.Weth,
        chainId
      );

      if (!l1DepositHelper || !l2) {
        return undefined;
      }

      const estGas = await l1DepositHelper.estimateGas.depositWETH(
        l2,
        l2GasLimit,
        [],
        {
          value: amount,
        }
      );

      return l1DepositHelper.depositWETH(l2, l2GasLimit, [], {
        value: amount,
        gasLimit: addAdditionalEstGasBuffer(estGas),
      });
    },
    [chainId, l1DepositHelper]
  );

  const depositERC20 = useCallback(
    async (
      l1TokenAddress: string,
      l2TokenAddress: string,
      amount: BigNumber,
      isYieldVault: boolean = false
    ) => {
      if (!l1StandardBridge) {
        return undefined;
      }

      // Encode the data as per the expected structure
      const encodedData = ethers.utils.defaultAbiCoder.encode(
        ["tuple(bool depositToYieldVault)"],
        [{ depositToYieldVault: true }]
      );

      const dataAsHex = isYieldVault ? encodedData : [];

      const estGas = await l1StandardBridge.estimateGas.depositERC20(
        l1TokenAddress,
        l2TokenAddress,
        amount,
        l2GasLimit,
        dataAsHex
      );

      const tx = await l1StandardBridge.depositERC20(
        l1TokenAddress,
        l2TokenAddress,
        amount,
        l2GasLimit,
        dataAsHex,
        {
          gasLimit: addAdditionalEstGasBuffer(estGas),
        }
      );
      return tx;
    },
    [l1StandardBridge]
  );

  // Deposit from optimism or arbitrum
  const depositERC20OptimismOrArbitrum = useCallback(
    async (
      amount: BigNumber,
      tokenAddress: string,
      assetToDeposit: DepositWithdrawCollaterals,
      isYieldVault: boolean = false
    ): Promise<IDepositERC20MultichainResponse | undefined> => {
      if (!vault || !account || !connectorAddress || !socketHelper) {
        return undefined;
      }

      // Re-estimate fees before submit
      const estFees = await estimateMinFees(connectorAddress);

      if (!estFees) {
        throw Error("No deposit fees");
      }

      // Encode the data as per the expected structure
      const encodedData = ethers.utils.defaultAbiCoder.encode(
        ["tuple(bool depositToYieldVault)"],
        [{ depositToYieldVault: true }]
      );

      const dataAsHex = isYieldVault ? encodedData : [];

      // Check limit
      const limit = await vault.getCurrentLockLimit(connectorAddress);

      // OK
      if (amount.lte(limit)) {
        const estimatedGas = await validateEstimatedGas(
          socketHelper.estimateGas.depositToAppChain(
            account,
            tokenAddress,
            amount,
            l1GasLimit,
            connectorAddress,
            dataAsHex,
            {
              value: estFees,
            }
          ),
          BigNumber.from("800000")
        );
        const tx = await socketHelper.depositToAppChain(
          account,
          tokenAddress,
          amount,
          l1GasLimit,
          connectorAddress,
          dataAsHex,
          {
            value: estFees,
            gasLimit: estimatedGas || 800000,
          }
        );
        return {
          insufficientLimit: undefined,
          tx,
        };
      }
      // NOT ENOUGH LIMIT
      return {
        insufficientLimit: limit,
        tx: undefined,
      };
    },
    [account, connectorAddress, estimateMinFees, socketHelper, vault]
  );

  const depositETHOptimismOrArbitrum = useCallback(
    async (
      amount: BigNumber
    ): Promise<IDepositERC20MultichainResponse | undefined> => {
      if (!vault || !account || !connectorAddress || !socketHelper) {
        return undefined;
      }

      // Re-estimate fees before submit
      const estFees = await estimateMinFees(connectorAddress);

      if (!estFees) {
        throw Error("No deposit fees");
      }

      // Check limit
      const limit = await vault.getCurrentLockLimit(connectorAddress);

      const estFeesBn = BigNumber.from(estFees);
      // OK
      if (amount.lte(limit)) {
        const estimatedGas = await validateEstimatedGas(
          socketHelper.estimateGas.depositETHToAppChain(
            account,
            amount,
            l1GasLimit,
            connectorAddress,
            [],
            {
              value: estFeesBn.add(amount),
            }
          ),
          BigNumber.from("800000")
        );

        const tx = await socketHelper.depositETHToAppChain(
          account,
          amount,
          l1GasLimit,
          connectorAddress,
          [],
          {
            value: estFeesBn.add(amount),
            gasLimit: estimatedGas || 800000,
          }
        );
        return {
          insufficientLimit: undefined,
          tx,
        };
      }
      // NOT ENOUGH LIMIT
      return {
        insufficientLimit: limit,
        tx: undefined,
      };
    },
    [account, connectorAddress, estimateMinFees, socketHelper, vault]
  );

  return {
    l1DepositHelper,
    socketHelper,
    depositETH,
    depositERC20,
    depositERC20WithPermit,
    depositERC20OptimismOrArbitrum,
    depositETHOptimismOrArbitrum,
    approvalSpender,
    depositFees,
  };
};

export default useDeposit;
