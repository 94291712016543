import { useIntercom } from "react-use-intercom";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { ICON_COLORS } from "../../constants/design/colors";
import { SPACING } from "../../constants/design/spacing";
import useWallet from "../wallet/useWallet";
import { useGetAccount } from "../api/account/useGetAccount";
import { IntercomContext } from "../../contexts/IntercomContext";
import { API_RIBBON_URL } from "../../constants/api/api";

interface IGetIntercomUserResponse {
  found: boolean;
}
export function useIntercomHook() {
  const { setShowIntercomSetupModal } = useContext(IntercomContext);
  const { boot, show, update, shutdown, hide, isOpen } = useIntercom();
  const { account } = useWallet();
  const { data: accountData } = useGetAccount();

  const [hasContact, setHasContact] = useState<boolean | null>(null);

  const checkContact = useCallback(async () => {
    try {
      // check if user is found
      if (account) {
        const response = await axios.get(
          `${API_RIBBON_URL}/api/get-intercom-user?account=${account}`
        );
        const data = response.data.data as IGetIntercomUserResponse;
        if (data.found) {
          setHasContact(true);
        } else {
          setHasContact(false);
        }
      }
    } catch (error) {
      setHasContact(false);
    }
  }, [account]);

  useEffect(() => {
    checkContact();
  }, [checkContact]);

  const bootConfig = useMemo(() => {
    const userId = account || "";
    const userHash = accountData?.intercom_hash;
    const actionColor = ICON_COLORS.one;
    const hideDefaultLauncher = true;
    const verticalPadding = SPACING.five;
    const horizontalPadding = SPACING.five;
    return {
      actionColor,
      hideDefaultLauncher,
      verticalPadding,
      horizontalPadding,
      ...(userHash &&
        (accountData.email_address || hasContact) && { userId, userHash }),
    };
  }, [account, accountData, hasContact]);

  useEffect(() => {
    boot(bootConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIntercomEmailUpdate = useCallback(() => {
    if (account && accountData?.email_address && accountData?.intercom_hash) {
      update({
        userHash: accountData.intercom_hash,
        email: accountData.email_address,
      });
    }
  }, [account, accountData, update]);

  const rebootIntercom = useCallback(() => {
    shutdown();
    boot(bootConfig);
    handleIntercomEmailUpdate();
    show();
  }, [boot, bootConfig, handleIntercomEmailUpdate, show, shutdown]);

  const handleIntercomButtonClicked = useCallback(() => {
    // we can create a contact manually
    // so we check if the account address has a contact then boot up intercom
    if (account && (hasContact || accountData?.email_address)) {
      rebootIntercom();
    } else {
      setShowIntercomSetupModal(true);
    }
  }, [
    account,
    accountData?.email_address,
    hasContact,
    rebootIntercom,
    setShowIntercomSetupModal,
  ]);

  return {
    bootConfig,
    rebootIntercom,
    hide,
    isOpen,
    hasContact,
    handleIntercomButtonClicked,
  };
}
